import React, { Component } from 'react'
import './ItemImage'
import NoImage from '../../../../assets/img/noimage.jpeg'
import { data } from 'jquery'
export default class ItemImage extends Component {
  
    constructor(props){
        super(props)
        this.state = {
            visible: false,
            fileName: '',
            file: null,
            invalidFile: false,
            touched: false,
            validImage: true
        }
    }

    toggleButton = () =>{
        const { visible } = this.state
        const newState = !visible
        this.setState({ visible : newState })
    }

    handleFileChange = (e) => {
        const { target: { files } } = e
        var file = e.target.files[0];
        const [{ name }] = files;

        var reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = (e) => {
          var img = document.createElement("img");

          img.onload = () => {
            var canvas = document.createElement('canvas');
            var ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0);
      
            var MAX_WIDTH = 128;
            var MAX_HEIGHT = 128;
            
            var width = img.width;
            var height = img.height;
      
            if (width > height) {
              if (width > MAX_WIDTH) {
                height *= MAX_WIDTH / width;
                width = MAX_WIDTH;
              }
            } else {
              if (height > MAX_HEIGHT) {
                width *= MAX_HEIGHT / height;
                height = MAX_HEIGHT;
              }
            }

            canvas.width = width;
            canvas.height = height;
            var ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0, width, height);
            var dataurl = canvas.toDataURL("image/png");

            var blob = null;
            var xhr = new XMLHttpRequest();
            xhr.open("GET", dataurl);
            xhr.responseType = "blob";//force the HTTP response, response-type header to be blob
            xhr.send()
            xhr.onload = () =>
            {
               blob = xhr.response;//xhr.response is now a blob object      
               this.setState({ file: blob, fileName: name, invalidFile: false, touched: true });
               const { areaId, index } = this.props
               this.props.handleImageChange(areaId, index, blob, name)
            }
            this.setState({ previewSrc : dataurl });
          }
          
          img.src = e.target.result;
        }
    }

    invalidURL = () =>{
        this.setState({ validImage: false })
    }

    resetStates = () =>{
        this.setState({
            visible: false,
            fileName: '',
            file: null,
            invalidFile: false,
            touched: false,
            validImage: true
        })
    }

    render() {
        const { visible, fileName, invalidFile, validImage } = this.state 

        return (
            <>
                <div 
                    onMouseEnter={() => this.toggleButton()}
                    onMouseLeave={() => this.toggleButton()}
                    className="image-container">

                    {visible ?
                        <label className="change-image-label">
                            <span style={{'color': 'yellow'}}>Change Image</span>
                        </label> 
                        : invalidFile ? 
                        <label className="change-image-label">
                            <span style={{'color': 'red'}}>Invalid Image</span>
                        </label> : ''}
                    <input
                        onChange={(e) => this.handleFileChange(e)}
                        type="file"
                        className="change-image-input"/>

                    {
                        fileName ?
                        <img 
                        onError={this.invalidURL}
                        className="item-image" 
                        src={this.state.previewSrc} 
                        alt={`item-image${this.props.index}`}/>
                        :
                        validImage ?
                        <img 
                        onError={this.invalidURL}
                        className="item-image" 
                        src={this.props.imgURL} 
                        alt={`item-image${this.props.index}`}/>
                        : 
                        <img 
                        className="item-image" 
                        src={NoImage} 
                        alt={`item-image${this.props.index}`}/>}
                </div>
            </>
        )
    }
}
