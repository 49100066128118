import React, { Component } from 'react'
import {
    Col,
    Row,
    Card,
    CardBody,
    CardHeader,
    Label,
    Input,
    FormGroup,
  } from "reactstrap";

const ProjectTerm = (props) => {

        const styles = {
            minHeight: '200px'
        }

        return (
            <Row>
                <Col md="12">
                    <Card>
                    <CardHeader>
                        <h2 id="profit">Project Terms</h2>
                    </CardHeader>
                    <CardBody>
                    <FormGroup>
                    <Input type="textarea"
                        style={styles} 
                        name="projectTerms" id="projectTerms" 
                        onChange={props.handleChange}
                        placeholder ="
                        This proposal is valid for 30 days. Upon signature or receipt of deposit, 
                        this proposal will become a formal contract. All client furnished products 
                        are subject to time and material billing above estimated labor amounts. 
                        A 15% Restocking Fee will be assessed to any contracted item that is 
                        returned for reasons other than product defect. Electric City Smart Home 
                        warranties our labor and installation for 12 months. If a product purchased 
                        from Electric City Smart Home fails during that period, we cover removal 
                        and reinstallation costs to satisfy the relevant manufacturer's warranty. 
                        Service and maintenance agreements are available to cover system beyond the 
                        initial warranty period. Please ask your consultant for details. "
                        defaultValue={props.projectTerms}/>
                    </FormGroup>
                    </CardBody>
                    </Card>
                </Col>
            </Row>
        )
}


export default ProjectTerm