import React, { Component, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { withRouter } from "react-router-dom";
import { withFirebase } from "../Firebase";
import { compose } from "recompose";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const SignUp = (props) => {
    const {
      buttonLabel,
      className
    } = props;
  
    const [modal, setModal] = useState(false);
  
    const toggle = () => setModal(!modal);
  
    return (
      <div>
        {/*
          Sign up disabled for beta use
        <Button color="link" onClick={toggle}>Sign Up</Button>
        */}
        <Modal isOpen={modal} toggle={toggle} className={className}>
          <ModalHeader toggle={toggle}>Sign Up</ModalHeader>
          <ModalBody>
              <SignUpForm/>ç
          </ModalBody>
        </Modal>
      </div>
    );
  }
  
  export default SignUp;
  
  const SignUpFormBase = (props) => {
    const validationSchema = yup.object().shape({
      email: yup.string()
        .email('Invalid email address format')
        .required('E-mail is required!'),
      password: yup.string()
        .min(6, 'Password has must be at least 6 characters!')
        .required('Password is required!'),
      passwordConfirmation: yup.string()
        .oneOf([yup.ref('password')], 'Passwords must match')
        .required('Passwords must match')
    });
    const handleSignUpErrors = (error) => {
      switch (error.code) {
        case 'auth/weak-password':
          return { 'password': error.message };
        default:  // invalid-email, email-already-in-use, operation-not-allowed
          return { 'email': error.message };
      }
    }
    return (
      <Formik
        initialValues={{ email: '', password: '', passwordConfirmation: '' }}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          props.firebase
            .doCreateUserWithEmailAndPassword(values.email, values.password)
            .then(authUser => {
              // Create a user in RT database
              return props.firebase
                .userDRef(authUser.user.uid)
                .set({ 'email': values.email });
            })
            .then(authUser => {
              // reset form and move to proposals page
              actions.setSubmitting(false);
              actions.resetForm();
              props.history.push("/proposals");
            })
            .catch(error => {
              actions.setSubmitting(false);
              actions.setErrors(handleSignUpErrors(error));
            });
        }}
      >
        {({ isSubmitting, touched, errors }) => (
          <Form>
            <div className="form-group">
              <label for="email">Email</label>
              <Field
                type="email"
                name="email"
                className={`form-control ${touched.email && errors.email ? "is-invalid" : ""}`}
              />
              <ErrorMessage name="email" component="div" className="invalid-feedback" />
            </div>
            <div className="form-group">
              <label for="password">Password</label>
              <Field
                type="password"
                name="password"
                className={`form-control ${
                  touched.password && errors.password ? "is-invalid" : ""
                  }`}
              />
              <ErrorMessage name="password" component="div" className="invalid-feedback" />
            </div>
            <div className="form-group">
              <label for="passwordConfirmation">Confirm Password</label>
              <Field
                type="password"
                name="passwordConfirmation"
                className={`form-control ${
                  touched.passwordConfirmation && errors.passwordConfirmation ? "is-invalid" : ""
                  }`}
              />
              <ErrorMessage name="passwordConfirmation" component="div" className="invalid-feedback" />
            </div>
            <button type="submit" disabled={isSubmitting} className="btn btn-primary">
              Sign Up
            </button>
  
          </Form>
        )}
      </Formik>
      // <form onSubmit={this.onSubmit}>
      //   <input
      //     name="username"
      //     value={username}
      //     onChange={this.onChange}
      //     type="text"
      //     placeholder="Full Name"
      //   />
      //   <input
      //     name="email"
      //     value={email}
      //     onChange={this.onChange}
      //     type="text"
      //     placeholder="Email Address"
      //   />
      //   <input
      //     name="password"
      //     value={password}
      //     onChange={this.onChange}
      //     type="password"
      //     placeholder="Password"
      //   />
      //   <input
      //     name="passwordConfirmation"
      //     value={passwordConfirmation}
      //     onChange={this.onChange}
      //     type="password"
      //     placeholder="Confirm Password/"
      //   />
      //   <button disabled={isInvalid} type="submit">
      //     Sign Up
      //     </button>
      //   {error && <p>{error.message}</p>}
      // </form>
    );
  }
  
  // const SignUpLink = () => (
  //   <p>
  //     Don't have an account? <Link to={ROUTES.SIGN_UP}>Sign Up</Link>
  //   </p>
  // );
  const SignUpForm = compose(
    withRouter,
    withFirebase
  )(SignUpFormBase);
  
  //export default SignUpForm;
  
  

