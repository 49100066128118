import React, { Component } from 'react'

import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    Form,
} from "reactstrap";

const PromptSaveModal = (props) => {

    const handleSave = async (e) =>{
        await props.handleBatch(e)
        return props.navigate()
    }

        return (
            <Modal isOpen={props.modal}>
                <ModalHeader toggle={props.callBack}>
                    {props.prompt}
                </ModalHeader>
                    <ModalBody>
                        <Form>
                            <Button 
                                onClick={handleSave}
                                color="success">
                                {props.positive}
                            </Button>
                            <Button 
                                onClick={() => props.navigate()}
                                color="secondary">                
                                {props.negative}
                            </Button>
                        </Form>
                    </ModalBody>
            </Modal>
        )
}

export default PromptSaveModal