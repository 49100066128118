import React from "react";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { withRouter } from "react-router-dom";
import { withFirebase } from "../Firebase";
import { compose } from "recompose";
import { Row, Col, Button } from "reactstrap";
import ForgotPass from "../LogInOut/forgotpass.js";



const SignInFormBase = (props) => {
    const validationSchema = yup.object().shape({
      email: yup.string()
        .email('Invalid email address format')
        .required('E-mail is required!'),
      password: yup.string()
        .required('Password is required!')
    });
    const handleSignInErrors = (error) => {
      switch (error.code) {
        case 'auth/wrong-password':
          return { 'password': error.message };
        default:  // user-not-found, user-disabled, invalid-email
          return { 'email': error.message };
      }
    }
    return (
      <Formik
        initialValues={{ email: '', password: '' }}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          props.firebase
            .doSignInWithEmailAndPassword(values.email, values.password)
            .then(() => {  // on successful login
              actions.setSubmitting(false);
              actions.resetForm();
              // switch view to proposals page
              props.history.push("/proposals");
            })
            .catch(error => {
              actions.setSubmitting(false);
              actions.setErrors(handleSignInErrors(error));
            });
        }}
      >
        {({ isSubmitting, touched, errors }) => (
          <Form>
            <div className="form-group">
              <label for="email">Email</label>
              <Field
                type="email"
                name="email"
                className={`form-control ${touched.email && errors.email ? "is-invalid" : ""}`}
              />
              <ErrorMessage name="email" component="div" className="invalid-feedback" />
            </div>
            <div className="form-group">
              <label for="password">Password</label>
              <Field
                type="password"
                name="password"
                className={`form-control ${
                  touched.password && errors.password ? "is-invalid" : ""
                  }`}
              />
              <ErrorMessage name="password" component="div" className="invalid-feedback" />
            </div>
            <Row>
              <Col>
                <Button type="submit" disabled={isSubmitting} className="btn btn-primary">
                  Submit
                </Button>
              </Col>
              <Col>
                <p className={"text-sm-center mt-3"}>
                  <ForgotPass/>
                </p>
              </Col>
            </Row>
  
          </Form>
        )}
      </Formik>
    );
  }
  
  
  // onSubmit = event => {
  //   const { email, password } = this.state;
  //   this.props.firebase
  //     .doSignInWithEmailAndPassword(email, password)
  //     .then(() => {
  //       this.setState({ ...INITIAL_STATE });
  //       this.props.history.push(ROUTES.PROPOSALS);
  //     })
  //     .catch(error => {
  //       this.setState({ error });
  //     });
  //   event.preventDefault();
  // };
  // onChange = event => {
  //   this.setState({ [event.target.name]: event.target.value });
  // };
  //   render() {
  //     const { email, password, error } = this.state;
  //     const isInvalid = password === "" || email === "";
  //     return (
  //       // <div className={classes.form}>
  //       //   <Form onSubmit={this.onSubmit}>
  //       //     <Form.Group controlId="email">
  //       //       <Form.Label>Email</Form.Label>
  //       //       <Form.Control
  //       //         autoFocus
  //       //         size='lg'
  //       //         type="email"
  //       //         placeholder="Email Address"
  //       //         //value={email}
  //       //         defaultValue={email}
  //       //         onChange={this.onChange}
  //       //       />
  //       //     </Form.Group>
  //       //     <Form.Group controlId="password">
  //       //       <Form.Label>Password</Form.Label>
  //       //       <Form.Control
  //       //         size='lg'
  //       //         type="password"
  //       //         placeholder="Password"
  //       //         defaultValue={password}
  //       //        // value={password}
  //       //         onChange={this.onChange}
  //       //       />
  //       //     </Form.Group>
  //       //     <Button
  //       //       block
  //       //       size='lg'
  //       //       disabled={isInvalid}
  //       //       type="submit"
  //       //     >Sign In</Button>
  
  //         // </Form>
  //       // </div>
  //     // );
  //   // }
  // }
  
  const SignInForm = compose(
    withRouter,
    withFirebase
  )(SignInFormBase);
  export default SignInForm;




