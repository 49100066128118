import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import emailjs from 'emailjs-com';
import PromptSave from '../PromptSaveModal';
import './EmailModal.css'
import { withFirebase } from "../../Firebase";

import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    Input,
    Label,
    FormGroup,
    Form
} from "reactstrap";

class EmailModal extends Component {

    constructor(props){
        super(props)
        this.state = {
            isSubmitting: false,
            disabled: true,
            proposalName: '',
            error: '',
            clientEmail: '',
            message: '',
            organizationName: '',
            userId: '',
            proposalId: '',
            emailSent: false
        }
    }

    componentDidMount(){
        this.setState({ disabled: true }) 

        this.props.firebase.userDRef(this.props.firebase.auth.currentUser.uid)
        .get().then(doc =>{
            const { organizationName } = doc.data()
            this.setState({ organizationName,  userId: doc.id }) 
        })
        .catch(()=>{ this.setState({ error : "error occured loading user info" })  })
    }

    handleChange = ({ target: { value, name }}) =>{
        this.setState({ [name] : value, disabled: false })
    }

    sendEmail = (e) =>{
        
        e.preventDefault();
        const { clientEmail, organizationName, message, userId } = this.state
        const { docRef, proposalSnippetId } = this.props.location.state.response

        const emailForm = {
            "client_email": clientEmail,
            "company_name": organizationName,
            "client_name": this.props.clientName,
            "message": message,
            //Change this URL to the deployment URL later on
            "proposal_URL": `http://localhost:3000/proposal-review/${userId}-${docRef}.${proposalSnippetId}`
        }

        this.setState({ isSubmitting: true, error: '' }) 

        emailjs.send('gmail', 'template_MsoztQSU', emailForm, 'user_zCoI9SsnVdHyxPNC8jsRs')
          .then((result) => {
            this.setState({  error: '', isSubmitting: false, emailSent: true}) 
            setTimeout(() => {
                this.toggleEmailModal()
              }, 2000)
            console.log(result.text);
          }, (error) => {
              this.setState({ error: "Error sending email", isSubmitting: false }) 
              console.log(error.text);
         });
    }

    toggleEmailModal(){
        this.setState({ error: '' }) 
        this.props.toggleEmailModal()
    }

    render(){

        return (
            <>
            {this.props.formTouched ?
            <PromptSave
                prompt={"Save the changes before sending?"}
                positive={"Yes"}
                negative={"No"}
                navigate={this.props.setFormTouchedToFalse}
                handleBatch={this.props.handleBatch}
                modal={this.props.modal}/>
            :
             <Modal 
                size="lg" style={{ maxWidth: '2600px', width: '60%'}}
                isOpen={this.props.modal} toggle={() => this.toggleEmailModal()}>
                <ModalHeader toggle={() => this.props.toggleEmailModal()}>
                    Send email to your client
                </ModalHeader>
                    <ModalBody>
                    <Form
                        onSubmit={(e) => this.sendEmail(e)}>
                            <Label>Client Name</Label>
                            <h5>{this.props.clientName}</h5>
                            <FormGroup>
                                <Label>Email</Label>
                                <Input 
                                    style={{ maxWidth: '200px'}} 
                                    onChange={this.handleChange}
                                    type="email" 
                                    name="clientEmail" />
                            </FormGroup>
                            <FormGroup>
                                <Label>Message</Label>
                                <Input 
                                    style={{ minHeight: '200px'}} 
                                    onChange={this.handleChange}
                                    type="textarea" 
                                    name="message"/>
                            </FormGroup>
                        <div className="email-modal-button-container">
                            <Button 
                                color="primary"
                                type="submit"
                                disabled={this.state.disabled || this.state.isSubmitting}>
                                Send
                            </Button>
                            <Button color="secondary" onClick={() => this.toggleEmailModal()}>
                                Cancel
                            </Button>
                            <h5 
                                style={{ 'color' : this.state.error ? 'red' : 'green'}}
                                className="email-modal-message">
                             {this.state.error ? this.state.error : this.state.isSubmitting ? 'Sending...' : (this.state.emailSent ? 'Email sent!' : '')}
                            </h5>
                        </div>
                    </Form>
                </ModalBody>
                </Modal>}
            </>
        );
    }
}
export default withFirebase(withRouter(EmailModal));
