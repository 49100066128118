import React, { Component } from 'react';
import { CustomInput, FormGroup, ListGroup, Label, Button, Form } from 'reactstrap';
import { withFirebase } from '../Firebase';

class BusinessCardUpload extends Component {

    constructor(props){
        super(props)
        
        this.state = {
            fileName: '',
            file: null,
            invalidFile: false,
            touched: false
        }
    }

    handleFileChange = (e) => {
        const { target: { files } } = e
        var file = e.target.files[0];
        const [{ name }] = files;

        var reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = (e) => {
          var img = document.createElement("img");

          img.onload = () => {
            var canvas = document.createElement('canvas');
            var ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0);
      
            var MAX_WIDTH = 400;
            var MAX_HEIGHT = 400;
            
            var width = img.width;
            var height = img.height;
      
            if (width > height) {
              if (width > MAX_WIDTH) {
                height *= MAX_WIDTH / width;
                width = MAX_WIDTH;
              }
            } else {
              if (height > MAX_HEIGHT) {
                width *= MAX_HEIGHT / height;
                height = MAX_HEIGHT;
              }
            }

            canvas.width = width;
            canvas.height = height;
            var ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0, width, height);
            var dataurl = canvas.toDataURL("image/png");
            
            var blob = null;
            var xhr = new XMLHttpRequest();
            xhr.open("GET", dataurl);
            xhr.responseType = "blob";//force the HTTP response, response-type header to be blob
            xhr.send()
            xhr.onload = () =>
            {
               blob = xhr.response;//xhr.response is now a blob object      
               this.setState({ file: blob, fileName: name, invalidFile: false, touched: true });
               this.props.changeBusinessCardUrl(dataurl)
            }
            xhr.error = () =>{
                this.setState({ file: '', fileName: '', invalidFile: true, touched: true });
            }
          }
          
          img.src = e.target.result;
        }
    }

    create_UUID(){
        var dt = new Date().getTime();
        var string = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxx'
        var uuid = string.replace(/[xy]/g, function(c) {
            var r = (dt + Math.random()*16)%16 | 0;
            dt = Math.floor(dt/16);
            return (c=='x' ? r :(r&0x3|0x8)).toString(16);
        });
        return uuid;
    }

    handleSubmit = (e) =>{
        e.preventDefault()
        const { file, fileName } = this.state
        this.props.firebase
         .doUploadFile(file, this.create_UUID() + fileName )
           .then(uploadTaskSnapShot =>{
                uploadTaskSnapShot.ref.getDownloadURL()
                    .then(url =>{
                        //Need to save the image to the database after
                        this.props.firebase.userDRef(this.props.firebase.auth.currentUser.uid).update({ businessCardUrl: url })                     })
            })
    }

    render() {
        const { fileName, invalidFile, touched } = this.state;
        return (
                <ListGroup flush>
                        <Label for="exampleCheckbox">Item Image</Label>
                        <CustomInput
                            sm={5}
                            type="file"
                            id="exampleCustomFileBrowser"
                            name="customFile"
                            label={fileName || 'choose an image file'}
                            onChange={this.handleFileChange}
                            invalid={invalidFile}/>
                        <p className={"sm"}>
                            {(invalidFile) ? "invalid file:try again" : ""}
                        </p>
                </ListGroup>
        );
    }
}

export default withFirebase(BusinessCardUpload);
