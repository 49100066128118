
import React, { useState } from "react";
import classnames from "classnames";
import { withRouter } from "react-router-dom";
// reactstrap components
import {
  Nav,
  NavItem,
  Navbar,
  NavLink,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Card,
  CardHeader,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  TabPane,
  TabContent,
  Button,
  Form,
  FormGroup,
  Label,
  Input
} from "reactstrap";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { withAuthorization } from "../components/Session";
import AllContent from "../components/Proposals/allContent.js";
import ProposalForm from "../components/Proposals/proposalForm.js";
import CreateProposalModal from "../components/Proposals/createProposalModal.js";
import { debug } from "util";
import { create } from "istanbul-reports";
class Proposals extends React.Component {

  render() {
    
    return (
      <Router>
        <div className="content">
          <Route exact path="/proposals" component={ProposalCard} />
          <Route exact path="/proposals/form" component={ProposalForm} />
        </div>
      </Router>
    );
  }
}

const condition = authUser => !!authUser;
export default withRouter(withAuthorization(condition)(Proposals));
//export default withAuthorization(Proposals);

const ProposalCard = props => {
  // sets the dropdownOpen variable to false
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  // sets the activeTab variable to 0 (all proposals tab)
  const [activeTab, setActiveTab] = useState("0");
  const toggleTabs = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  // modal state
  const [modal, setModal] = useState(false);

  const toggleCreateProposalModal = () => setModal(!modal);

  // create new proposal button
  const createNewProposal = () => {
    // props.history.push("/proposals/form");
    // check if name already exists
  };

  return (
    <div>
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    // sets className to "active" if activeTab matches tab #
                    className={classnames({ active: activeTab === "0" })}
                    onClick={() => {
                      toggleTabs("0");
                    }}
                  >
                    All
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "1" })}
                    onClick={() => {
                      toggleTabs("1");
                    }}
                  >
                    Drafts
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "2" })}
                    onClick={() => {
                      toggleTabs("2");
                    }}
                  >
                    Changes Required
                  </NavLink>
                </NavItem>
                <Dropdown nav isOpen={dropdownOpen} toggle={toggleDropdown}>
                  <DropdownToggle nav caret>
                    More
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem header>Accepted</DropdownItem>
                    <DropdownItem disabled>Declined</DropdownItem>
                    <DropdownItem>Completed</DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem>History</DropdownItem>
                  </DropdownMenu>
                </Dropdown>
                <NavItem>
                  <NavLink
                    disabled
                    className={classnames({ active: activeTab === "4" })}
                    onClick={() => {
                      toggleTabs("4");
                    }}
                  >
                    Public Database
                  </NavLink>
                </NavItem>
                <Button
                  color="danger"
                  size="sm"
                  className="ml-auto"
                  onClick={toggleCreateProposalModal}
                >
                  + Create New
                </Button>
              </Nav>
            </CardHeader>
            <CardBody>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="0">
                  <Row>
                    <Col sm="12">
                      <AllContent />
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="1">
                  <Row>
                    <Col sm="12">
                      <h4>Drafts</h4>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="2">
                  <Row>
                    <Col sm="12">
                      <h4>Changes Required</h4>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="4">
                  <Row>
                    <Col sm="12">
                      <h4>Public Database</h4>
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <CreateProposalModal
        modal={modal}
        toggleCreateProposalModal={toggleCreateProposalModal}
        createNewProposal={createNewProposal}
      />
    </div>
  );
};
