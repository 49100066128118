import React, { Component } from 'react'
// form validation imports
import { withRouter } from "react-router-dom";
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    Input
} from "reactstrap";


class NewAreaModal extends Component {

    constructor(props){
        super(props)
        this.state = {
            isSubmitting: false,
            areaName: '',
            error: ''
        }
    }

    handleChange = ({ target: { value }}) =>{
        this.setState({ areaName: value })
    }

    handleSubmit = (e) =>{
        e.preventDefault()
        const { areaName } = this.state
        const areas = this.props.areas || {}
        let validName = true

        for(const key in areas){
            if(areas[key].areaName === areaName){
                this.setState({ error: `${areaName} already exits`})
                validName = false
            }
        }

        if(!areaName){
            this.setState({ error: `Area name required`})
            validName = false
        }

        if(validName){
            this.setState({ error: '', areaName: '' })
            this.props.addArea(areaName)
            this.props.toggleCreateNewAreaModal()
        }
    }

    render() {
        
        return (
            <>
                <Modal isOpen={this.props.modal} toggle={this.props.toggleCreateNewAreaModal}>
                <ModalHeader toggle={this.props.toggleCreateNewAreaModal}>
                        Create New Area
                </ModalHeader>
                    <ModalBody>
                                <Form
                                    onSubmit={(e) => this.handleSubmit(e)}>
                                    <div className="form-row">
                                        <div className="form-group col-md-6">
                                            <label htmlFor="areaName">Area Name</label>
                                            <Input
                                                type="text"
                                                htmlFor="areaName"
                                                onChange={(e) => this.handleChange(e)}
                                                name="areaName"
                                                //className={`form-control ${touched.areaName && errors.areaName ? "is-invalid" : ""}`}
                                            />
                                            <p style={{'color':'red'}}>{this.state.error}</p>
                                            {/*<Error name="areaName" component="div" className="invalid-feedback" />*/}
                                        </div>
                                    </div>
                                    <Button color="primary"
                                        type="submit"
                                        disabled={this.state.isSubmitting}>
                                        Create
                                    </Button>
                                    <Button color="secondary" onClick={this.props.toggleCreateNewAreaModal}>
                                        Cancel
                                    </Button>
                                </Form>
                    </ModalBody>
                </Modal>
            </>
        )
    }
}

export default withRouter(NewAreaModal)