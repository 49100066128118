import React, { Component } from "react";
import { withFirebase } from "../Firebase";

import ProposalButton from "./proposalButton.js";

// bootstrap
import { ListGroup, ListGroupItem } from "reactstrap";

class AllContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      proposalSnippets: []
    };
  }

  removeProposalFromList = (id) =>{
    const { proposalSnippets } = this.state
    const newProposalSnippets = proposalSnippets.filter(proposal =>proposal.key !== id )
    this.setState({ proposalSnippets : newProposalSnippets})
  }

  componentDidMount() {

    this.setState({ loading: true });
    this.props.firebase
      .userDRef(this.props.firebase.auth.currentUser.uid).collection('proposal-snippets')
      .orderBy("lastModified")
      .limit(5)
      .get()
      .then(querySnapshot => {
        let proposalSnippets = [];
        
        querySnapshot.forEach(doc => {
          const data = doc.data();
          proposalSnippets.push(
            <ListGroupItem 
              key={doc.id}>
              <ProposalButton 
                proposalSnippetId={doc.id}
                removeProposalFromList={this.removeProposalFromList}
                docRef={data.proposalDocRef}
                proposalName={data.proposalName}
                clientName={data.clientName}
                lastModified={data.lastModified.toDate().toLocaleString()}
                status={data.status}/>
            </ListGroupItem>
          );
        });
        this.setState({ proposalSnippets, loading: false });
      })
      .catch(error => {
        console.log("Error getting documents: ", error);
      });
  }

  componentDidUpdate(){
    //Creating or deleting a project causes the scroll bar to disappear and this fixes it
    const body = document.getElementsByTagName("body")[0];
    body.style.overflowY = "auto" 
  }

  render() {
    const { proposalSnippets, loading } = this.state;
    return (
      <div>
        {loading && <div>Loading ...</div>}
        <ListGroup flush>
          {proposalSnippets}
        </ListGroup>
      </div>
    );
  }

}

export default withFirebase(AllContent);