import React, { Component } from 'react'
import { withFirebase } from "../../Firebase";
//import { withRouter } from "react-router-dom";
//import FileUpload from '../../FileUpload/fileupload'
import ItemImage from './ItemImage/ItemImage'
import './AreasAndItems.css'
import NewAreaModal from './NewAreaModal'
import RemoveAreaModal from './RemoveAreaModal'

//add table to this
import {
  Col,
  Row,
  Card,
  CardBody,
  CardHeader,
  ListGroup,
  FormGroup,
  Table,
  Button,
  Input,
  Label
} from "reactstrap";
import { withRouter } from 'react-router';

class AreasAndItems extends Component {

    constructor(props){
        super(props)
        this.state = {
            addAreaModal: false,
            removeAreaModal: false,
        }
    }

    toggleCreateNewAreaModal = () =>{
        const newState =  !this.state.addAreaModal
        this.setState({ addAreaModal : newState })
    }

    toggleRemoveAreaModal = (areaName) =>{
        const currentProps = this.props.location.state.response
        const newState =  !this.state.removeAreaModal
        this.setState({ removeAreaModal : newState })    
        this.props.history.push('/proposals/form',{ response: { ...currentProps, currentArea: areaName }})
    }

    renderItems(areaId){
        const { areas } = this.props
        const items = areas[areaId].items || []
       return (
           <Table>
               <thead>
                   <tr>
                       <th></th>
                       <th>Name</th>
                       <th>Qty</th>
                       <th>Price (USD)</th>
                       <th>Description</th>
                       <th></th>
                       <th></th>
                   </tr>
               </thead>
               {
                   items.map((item, index) =>{
                       if(!item.deleted){
                        return (
                            
                            <tbody key={index}>
                                <tr className="item-table">
                                    <td>
                                        <ItemImage
                                            areaId={areaId}
                                            handleImageChange={this.props.handleImageChange}
                                            imgURL={item.imgURL}
                                            index={index}/>
                                    </td>
                                    <td>
                                        <FormGroup>
                                            <Input 
                                                onChange={(e) => this.props.handleItemChange(e, areaId, index)}
                                                name="name"
                                                className="name"
                                                defaultValue={item.name}/>
                                        </FormGroup>
                                    </td>
                                    <td>
                                        <FormGroup>
                                            <Input 
                                                onChange={(e) => this.props.handleItemChange(e, areaId, index)}
                                                name="qty"
                                                className="qty"
                                                type="number"
                                                step="1"
                                                min="0"
                                                defaultValue={item.qty}/>
                                        </FormGroup>
                                    </td>
                                    <td>
                                        <FormGroup>
                                            <Input 
                                                onChange={(e) => this.props.handleItemChange(e, areaId, index)}
                                                name="price"
                                                className="price"
                                                type="number"
                                                step="1"
                                                min="0"
                                                defaultValue={item.price}/>
                                        </FormGroup>
                                    </td>
                                    <td>
                                        <FormGroup>
                                            <Input
                                                className="desc"
                                                type="textarea"
                                                onChange={(e) => this.props.handleItemChange(e, areaId, index)}
                                                name="desc"
                                                defaultValue={item.desc}/>
                                        </FormGroup>
                                    </td>
                                    <td>
                                            <Label className="item-check-button-label">
                                                <Input 
                                                    defaultValue={true}
                                                    onChange={this.props.checkItem.bind(this, areaId, index)}
                                                    type="checkbox"
                                                    className="item-check-button"
                                                    checked={item.dbItem}/> 
                                                    Save in item database
                                            </Label>                       
                                    </td>
                                    <td>
                                        <Button
                                             className="item-remove-button"
                                             onClick={this.props.removeItem.bind(this, areaId, index)}>
                                            Remove Item
                                        </Button>
                                    </td>
                                </tr>
                            </tbody>)
                       }
                   })
               }
               
           </Table>
       )
   }

    renderAreas(){
        const { areas, areaOrder } = this.props
        const userAreas = areaOrder.map((areaId, index) =>{
            const areaName = areas[areaId].areaName
            let invalid = false
            let keyCount = 0
            for(const key in areas){
                if(areas[key].areaName === areaName && areaId !== key){
                    keyCount++;
                }
                if(keyCount > 0){ invalid = true }
            }

            return (
                <Card key={index} body style={{ backgroundColor: '#eee', borderColor: '#333' }}>
                    <CardHeader>
                        <div
                            className="header-and-remove-button">
                            <Input
                                className="area-name"
                                onChange={(e) => this.props.handleAreaChange(e, areaId)}
                                defaultValue={areaName}/>
                            {<p style={{ 'color': 'red'}}>{invalid ? `${areaName} already exists` : (areaName ? '' : 'Area name required')}</p>}
                            <Button
                                className="area-remove-button"
                                onClick={this.toggleRemoveAreaModal.bind(this, areaId)}>
                                Remove Area
                            </Button>
                        </div>
                    </CardHeader>
                    <CardBody>
                        <ListGroup>
                            {this.renderItems(areaId)}
                            <Button block
                                onClick={this.props.addItem.bind(this, areaId)}>
                                + Add Line Item
                            </Button>
                        </ListGroup>
                    </CardBody>
                </Card>
            )
        })

        return userAreas
    }

    render() {
        return (
            <>
            <Row>
              <Col md="12">
                <Card>
                    <CardHeader>
                        <h2 id="area">Areas and Items</h2>
                    </CardHeader>
                    <CardBody>
                        <Button 
                            onClick={this.toggleCreateNewAreaModal}
                            block>
                            + Add Room/Area
                        </Button>
                        <ListGroup>
                            {this.renderAreas()}
                        </ListGroup>
                    </CardBody>
                </Card>
            </Col>
         </Row>
        <NewAreaModal
            modal={this.state.addAreaModal}
            addArea={this.props.addArea}
            areas={this.props.areas}
            toggleCreateNewAreaModal={this.toggleCreateNewAreaModal}/>
        <RemoveAreaModal
                modal={this.state.removeAreaModal}
                toggleRemoveAreaModal={this.toggleRemoveAreaModal}
                removeArea={this.props.removeArea}/>
         </>
       
        )
    }
}

export default withRouter(withFirebase(AreasAndItems))