import React from "react";
import { Row, Col, Card, CardBody, CardHeader } from "reactstrap";
import SignUp from "../components/LogInOut/signup.js";
import SignInForm from "../components/LogInOut/signin.js";
import SignOutButton from "../components/LogInOut/signout.js";
import { AuthUserContext } from "../components/Session";

// Bootstrap
import "bootstrap/dist/css/bootstrap.css";

const AccountPage = () => (
  <div className="content">
    <Row>
      <Col sm="12" md={{ size: 6, offset: 3 }}>
        <AuthUserContext.Consumer>
          {authUser => (authUser ? <SignOutButton /> : <SignInPage />)}
        </AuthUserContext.Consumer>
      </Col>
    </Row>
  </div>
);

export default AccountPage;

const SignInPage = () => (
  <Card>
    <CardHeader>
      <p className={"text-sm-left mt-3"}>
        <SignUp />
      </p>
      <h3 className="text-center mx-1">Sign In</h3>
    </CardHeader>
    <CardBody>
      <SignInForm />
    </CardBody>
  </Card>
);

