import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col
} from "reactstrap";
import { withRouter } from 'react-router-dom';
import { AuthUserContext } from "../components/Session";
import { withAuthorization } from "../components/Session";
import AccountPage from "./Account.jsx";
class ItemDatabase extends React.Component {
  render() {
    return (
      <>
        <div className="content">
          <ItemsCard/>
        </div>
      </>
    );
  }
}

const condition = authUser => !!authUser;
export default withRouter(withAuthorization(condition)(ItemDatabase));


const ItemsCard = () =>{
  return(
    <div>
      <Card>
        <CardHeader>
        <p>Item Database</p>
        </CardHeader>
      </Card>
    </div>
  );
}