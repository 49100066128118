/*eslint-disable*/
import React from "react";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col
} from "reactstrap";
import { withRouter } from 'react-router-dom';
import { AuthUserContext } from "../components/Session";
import { withAuthorization } from "../components/Session";
import AccountPage from "./Account.jsx";
//import { ReactComponent } from "*.svg";
class Notifications extends React.Component {

  render() {
    return (
      <>
        <div className="content">
          <NotificationTest/>
        </div>
      </>
    );
  }
}

const condition = authUser => !!authUser;
export default withRouter(withAuthorization(condition)(Notifications));

const NotificationTest = () => {
  return (
    <div>
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <CardTitle ><p>Notifications</p></CardTitle>
            </CardHeader>
            <CardBody>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};