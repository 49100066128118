import React, { useState, useEffect, useRef } from 'react'
import './StickyNav.css'
import { Link, withRouter } from 'react-router-dom'
import PromptSaveModal from '../PromptSaveModal'
import {
    Alert,
    Button,
    Input,
    Row,
    Card,
    Col,
    CardBody
} from "reactstrap";

const StickyNav = (props) => {

        const [offSet, setOffSet] = useState(0);
        const [cardWidth, setCardWidth] = useState(0);
        const [modal, setModal] = useState(false);
        const cardWidthRef = useRef();

        useEffect(() => {
            //use the div elemement to set the width of nav bar to be exact same as the beginning  
            //even after the "display: fixed" is applied 

            setCardWidth(cardWidthRef.current.scrollWidth)
            window.addEventListener('scroll', setOffSetValue)
            window.addEventListener('resize', setCardWidthValue)
            
            return () => {
                window.removeEventListener('scroll', setOffSetValue)
                window.removeEventListener('resize', setCardWidthValue)
            }
        })

        const setOffSetValue = () =>{
            setOffSet(window.pageYOffset)
        }

        const setCardWidthValue = () =>{
            const cardWidth = cardWidth ? cardWidth.current.scrollWidth : '80%'
            setCardWidth(cardWidth)
        }

        const { proposalName } = props 
        const proposalFontStyle = 
        {   
            fontSize: (proposalName.length < 21) ? '2.5rem'
            : (proposalName.length < 29) ? "2rem" 
            : (proposalName.length < 34) ? "1.7rem" 
            : (proposalName.length < 40) ? '1.4rem' 
            : "1.2rem"
        } 

        const checkIfFormIsTouched = () =>{
            if(!!props.formTouched){
                setModal(true)
            }
            else{
                props.history.push("/proposals")
            }
        }


        return (
            <>
            <Row >
            <Col md="12">
            <div width="100%" ref={cardWidthRef}/>
            <Card style={{ width: cardWidth + 'px' }} className={offSet > 92 ? "sticky" : ""}>
                <CardBody>
                    <div className="header-container">
                        <Input
                            name='proposalName'
                            className="proposal-input"
                            type="text"
                            style={proposalFontStyle}
                            onChange={(e) => props.handleChange(e)}
                            defaultValue={proposalName}/>
                        <div className="header-button-container">
                            <Button color="success" disabled={props.loading} onClick={props.handleBatch}>
                                Save
                            </Button>
                            <Button color="danger" onClick={props.togglePdfPreviewModal.bind(this, true)} disabled={props.loading}>
                                Preview in PDF
                            </Button>
                            <Button color="primary"  onClick={props.toggleEmailModal} disabled={props.loading} >
                                Send to Client
                            </Button>
                            <Button
                                onClick={checkIfFormIsTouched}>
                                    Back
                            </Button>
                        </div>
                    </div>
                </CardBody>
            </Card>
            <Alert style={{ width: cardWidth + 'px' }} className={`${offSet > 92 ? "alert-sticky" : ""} header-alert`} isOpen={props.visible}>Client Info Updated!</Alert>
            </Col>
            </Row>
            <Card style={{ width: cardWidth + 'px', opacity: "0", display: offSet > 92 ? 'block' : 'none'}}>
                <CardBody>
                    <div className="header-container">
                        <Input
                            className="proposal-input"
                            style={proposalFontStyle}
                            defaultValue={"Placeholder"}/>
                        <div className="header-button-container">
                        </div>
                    </div>
                </CardBody>
            </Card>
            <PromptSaveModal
                prompt={"Are you sure you want to leave without saving?"}
                positive={"Save the Changes"}
                negative={"Confirm"}
                navigate={() => props.history.push("/proposals")}
                handleBatch={props.handleBatch}
                modal={modal}/>
            </>
        )
}

export default withRouter(StickyNav)
