import React from 'react';
import { PDFViewer } from '@react-pdf/renderer';
import { Component } from 'react';
import './document.css'
import DocumentTemplate from './documentTemplate'
import { withFirebase } from "../../Firebase";
import NoImage from '../../../assets/img/noimage.jpeg'

class MyDocument extends Component {

  state = {
    imgURLs: [],
    imageReady: false
  }

  setStateSafely = (object) =>{
    if(this._mounted)
      this.setState(object)
  }

  makeLocalUrlGenerators = () =>{
    
    const { imgURLs }  = this.state
    const areaIds = Object.keys(imgURLs);
    const localURLGenerators = [];

    areaIds.map((areaId)=>{
      
      imgURLs[areaId].map((imgURL, index) => {
      
        localURLGenerators.push(async () =>{
          if(imgURL){
            const url = await this.props.getImageReference(imgURL).getDownloadURL()
            var xhr = new XMLHttpRequest();
            xhr.responseType = 'blob';
            xhr.onload = () =>{
              var blob = xhr.response;
              imgURLs[areaId][index] = URL.createObjectURL(blob)
              return this.setStateSafely({ imgURLs })
            };
            xhr.error = () =>{
              imgURLs[areaId][index] = NoImage
              return this.setStateSafely({ imgURLs })
            }  
            xhr.open('GET', url);
            xhr.send();

          }
        })
      })
    })

    return localURLGenerators
  }

  componentDidMount(){

    this._mounted = true
    const imgURLs = {}

    this.props.clientInfo.areaOrder.map(areaId =>{
      imgURLs[areaId] = []
      if(this.props.clientInfo.areas[areaId])
        this.props.clientInfo.areas[areaId].items.map((item, index)=>{
          imgURLs[areaId][index] = item.imgURL
      })
    })
    this.setState({ imgURLs }, () =>{
      const localURLgenerators = this.makeLocalUrlGenerators()
      
      Promise.all(localURLgenerators.map(func=>func()))
        .then(()=>{
          this.setState({ imageReady: true })
        })
    })
  }

  componentWillUnmount(){
    this._mounted = false
  }

  shouldComponentUpdate(){
    //prevent rerendor after the PDF is mounted
    if(this.state.imageReady){
      return false
    }
    else{
      return true
    }
  }

  render (){
        return (
        <>
          {this.state.imageReady ? 
            <PDFViewer id="PDFVIEWER">
              <DocumentTemplate
                userInfo={this.props.userInfo}
                clientInfo={this.props.clientInfo}
                imgURLs={this.state.imgURLs}/>
            </PDFViewer>
            : ''}
        </>
        )
  }

}

export default withFirebase(MyDocument)

/*
 <PDFDownloadLink document={doc} fileName="report.pdf">
  {({ blob, url, loading, error }) => (loading ? 'Loading document...' :      'Download')}
</PDFDownloadLink>*/

