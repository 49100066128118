import React, { Component } from 'react'
import {
    Col,
    Row,
    Card,
    CardBody,
    CardHeader,
    Label,
    Input,
    FormGroup,
} from "reactstrap";

const ProjectDescription = props => {

        const lableStyle = {
            fontSize: '1rem'
        }

        return (
            <Row>
                <Col md="12">
                    <Card>
                    <CardHeader>
                        <h2 id="description">Project Description</h2>
                    </CardHeader>
                    <CardBody>
                        <FormGroup>
                        <Label style={lableStyle} for="projectDescription">Short Description Of The Project</Label>
                        <Input 
                            onChange={props.handleChange}
                            type="textarea" 
                            defaultValue={props.projectDescription}
                            name="projectDescription" 
                            id="projectDescription"/>
                        </FormGroup>
                    </CardBody>
                    </Card>
                </Col>
            </Row>
        )
}

export default ProjectDescription