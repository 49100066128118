
import LandingPage from "views/LandingPage";
import Proposals from "views/Proposals.jsx";
import Notifications from "views/Notifications.jsx";
import ItemDatabase from "views/ItemDatabase.jsx";
import UserPage from "views/User.jsx";
import UpgradeToPro from "views/Upgrade.jsx";
import AccountPage from "views/Account.jsx";
import ProposalReview from "views/ProposalReview.jsx"

var routes = [
  {
    path: "/",
    name: "Welcome",
    icon: "nc-icon nc-bank",
    sideBar: false,
    component: LandingPage,
    layout: "/admin"
  },
  {
    path: "/proposals",
    name: "Proposals",
    icon: "nc-icon nc-bank",
    sideBar: true,
    component: Proposals,
    layout: "/admin"
  },
  {
    path: "/business-profile",
    name: "Business Profile",
    icon: "nc-icon nc-single-02",
    component: UserPage,
    sideBar: true,
    layout: "/admin"
  },
  {
    path: "/item-database",
    name: "Item Database",
    icon: "nc-icon nc-tile-56",
    component: ItemDatabase,
    sideBar: true,
    layout: "/admin"
  },
  {
    path: "/notifications",
    name: "Notifications",
    icon: "nc-icon nc-bell-55",
    component: Notifications,
    sideBar: true,
    layout: "/admin"
  },
  {
    path: "/account",
    name: "Account",
    icon: "nc-icon nc-circle-10",
    component: AccountPage,
    sideBar: true,
    layout: "/admin"
  },
  {
    pro: true,
    path: "/upgrade",
    name: "Upgrade to PRO",
    icon: "nc-icon nc-spaceship",
    component: UpgradeToPro,
    sideBar: true,
    layout: "/admin"
  },
  
  //Proposal View for the Receivers
  {
    path: "/proposal-review/:Id",
    name: "Proposal Review",
    component: ProposalReview,
    sideBar:  false,
    layout: "/admin"
  }
];

export default routes;
