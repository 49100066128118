import React, { Component } from "react";
// form validation imports
//import { Formik, Form, Field, ErrorMessage } from 'formik';
//import * as yup from 'yup';
import PromptSave from '../PromptSaveModal'
import {
    Button,
    Modal,
    ModalHeader,
} from "reactstrap";
import './PdfPreview.css'
import PDFDocument from './document'

class PdfPreviewModal extends Component {

    constructor(props){
        super(props)
    }

    goToEmail = () =>{
        this.props.togglePdfPreviewModal()
        this.props.toggleEmailModal()
    }

    closeModal = () =>{
        this.props.togglePdfPreviewModal(false)
    }

    render(){
        return (
                <Modal 
                    size="lg" style={{maxWidth: '2600px', width: '80%'}}
                    className="pdf-viewer-modal" isOpen={this.props.modal}>
                    <ModalHeader className="pdf-viewer-modal-header">
                            Preview
                            <div className="pdf-viewer-modal-buttons">
                                <Button 
                                    color="primary"
                                    onClick={this.goToEmail}>
                                    Send to Client
                                </Button>
                                <Button onClick={this.closeModal}>
                                    Back
                                </Button>
                            </div>
                    </ModalHeader>
                    <PDFDocument
                        userInfo={this.props.userInfo}
                        clientInfo={this.props.clientInfo}
                        modal={this.props.modal}
                        togglePdfPreviewModal={this.props.togglePdfPreviewModal}
                        getImageReference={this.props.getImageReference}/>
                 </Modal>)
    }
}

export default PdfPreviewModal;

