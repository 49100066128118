import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { withFirebase } from "../components/Firebase";
import { PDFViewer } from '@react-pdf/renderer';
import ReactDOM from 'react-dom'
import DocumentTemplate from '../components/Proposals/PdfPreviewModal/documentTemplate'
import '../components/Proposals/PdfPreviewModal/document.css'
import {
    Button,
    Form,
    FormGroup,
    Label,
    Input,
    Card,
    CardHeader,
    CardBody,
    Col,
    Row,
    Alert
} from "reactstrap";

class ProposalReview extends Component {

    constructor(props){
    
        super(props)
        this.state = {
             //Client values
            proposalName: '',
            firstName: '', lastName: '', address: '',
            address2: '', email: '', city: '', zipCode: '', usState: '',
            areaOrder: [], areas: {}, tax: null, 
            labor: null, discount: null, projectTerms: '', projectDescription: '',
            messageToClient: '', useProfile: false,
            //User values
            businessCardUrl : '', userEmail: '', userOrganizationName: '', 
            userPhoneNumber : null, showInProposals : false,
            alert: '',
            color: '#6bd098',
            loaded: false
        }
    }

    loadProposalInfo(userId, proposalId){
        this.props.firebase.userDRef(userId)
        .collection('proposals').doc(proposalId)
        .get().then(doc => {
          if (doc.exists) {
            //console.log("Document data:", doc.data());
            this.setState({ ...doc.data() }, () => { 
              const { areas } = this.state
              Object.keys(areas).map(key =>{
                  areas[key].items.map(item =>{
                    item.isAlreadyInDb = item.dbItem
                  })
              })
              this.setState({ areas })
            })
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        })
        .then(()=>{
          this.props.firebase.userDRef(userId)
            .get().then(userInfo =>{
                const { businessCardUrl, email, organizationName, phoneNumber, showInProposals }  = userInfo.data()
                const userFields = {
                  businessCardUrl, userEmail : email, userOrganizationName: organizationName, 
                  userPhoneNumber : phoneNumber, showInProposals
                }
                this.setState({ ...userFields })
           })
        })
        .then(() =>{
          this.setState({ loaded: true })
        })
        /*
        .then(() =>{
          this.state.areaRefIds.map((id, index) =>{
              this.props.firebase.userDRef(this.props.firebase.auth.currentUser.uid)
                  .collection('proposals').doc(this.props.location.state.response.docRef)
                  .collection('areas').doc(id).collection('areaItems')
                  .get().then(items =>{
                      const { areas } = this.state 
                      
                      const areaName = this.state.areaNames[index]
                      areas[areaName] = []
                      items.forEach(item => {
                          let newItem = item.data()
                          newItem.deleted = false
                          areas[areaName].push(newItem)
                      });
                      this.setState({ areas })
                  })
          })
        })
        */
        .catch(function (error) {
          console.log("Error getting document:", error);
        });
    }

    componentDidMount(){
        const { pathname } = this.props.history.location
        const documentId = pathname.replace('/proposal-review/', '')
        const userId = documentId.slice(0, documentId.indexOf('-')) 
        const proposalId = documentId.slice(documentId.indexOf('-') + 1, documentId.indexOf('.'))
        this.loadProposalInfo(userId, proposalId)
    } 

    getImageReference = (url) => {
        const httpsReference = this.props.firebase.doGetHttpReference(url)
        return httpsReference
    }

    componentDidUpdate() {
        const { firstName, lastName, address, proposalName,
            address2, email, city, zipCode, areas, usState, areaOrder, imagesChanged, tax, 
            labor, discount, projectTerms, projectDescription, messageToClient, useProfile,
            businessCardUrl, userEmail, userOrganizationName, 
            userPhoneNumber, showInProposals } = this.state
      
        const clientfields = { firstName, lastName, address, proposalName,
            address2, email, city, zipCode, areas, usState, areaOrder, imagesChanged,  
            tax, labor, discount, projectTerms, projectDescription, messageToClient, useProfile }
          
        const userfields = { businessCardUrl, userEmail, userOrganizationName, 
              userPhoneNumber, showInProposals }
        //setTimeout prevents rendering error 
        setTimeout(()=>{
            ReactDOM.unmountComponentAtNode(document.getElementById('PDF_CONTAINER'));
            if (this.state.loaded) {
            ReactDOM.render(
                <>
                <PDFViewer id="PDFVIEWER">
                    <DocumentTemplate
                    getImageReference={this.getImageReference}
                    userInfo={userfields}
                    clientInfo={clientfields}/>
                </PDFViewer>
                </>, document.getElementById('PDF_CONTAINER'))}}, 300)
    }

    render(){    

        const { pathname } = this.props.history.location
        const documentId = pathname.replace('/proposal-review/', '')
        const userId = documentId.slice(0, documentId.indexOf('-')) 
        const snippetId = documentId.slice(documentId.indexOf('.') + 1, documentId.length)

        return (
            <div className="content">
                <Col sm="10" md={12}>
                    <Card>
                        <CardHeader>
                                <AlertButtons
                                    snippetId={snippetId}
                                    userId={userId}
                                    firebase={this.props.firebase}/>
                        </CardHeader>
                        <CardBody>
                           <div id="PDF_CONTAINER"></div>
                        </CardBody>
                    </Card>
                </Col>
            </div>
        )
    }
}

export class AlertButtons extends Component {

    constructor(props){
        super(props)
        this.state = {
            alert: '',
            color: '#6bd098'
        }
    }

    submitResponse = (response) =>{
        this.setState({ alert: ``})
        const { snippetId, userId } = this.props

        const color = (response === 'Accepted') ? '6bd098' : 'grey'

        this.props.firebase.userDRef(userId)
            .collection('proposal-snippets').doc(snippetId).update({ status : response })
            .then(() =>{
                this.setState({ alert: `${response}!!`,  color})
            })
            .catch(()=>{ this.setState({ alert: `Error Submitting`, color: 'red' }) })
    }

    render(){
        
        return  (<Row>
                    <Col sm="3">
                        <Button onClick={this.submitResponse.bind(this, 'Accepted')}>
                            Accept
                        </Button>
                    </Col>
                    <Col sm="6" style={{"textAlign" : "center"}}>
                        <Alert 
                            style={{'background' : this.state.color, fontSize: "1.4rem"}}
                            isOpen={!!this.state.alert}>
                            {this.state.alert}
                        </Alert>
                    </Col>
                    <Col sm="3" style={{"textAlign" : "right"}}>
                        <Button onClick={this.submitResponse.bind(this, 'Rejected')}>
                            Reject
                        </Button>
                    </Col>
                </Row>)
    }
}

export default withFirebase(withRouter(ProposalReview))