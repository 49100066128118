import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    Form,
} from "reactstrap";

class RemoveAreaModal extends Component {

    removeAndExit = (areaName) =>{
        this.props.removeArea(areaName)
        this.props.toggleRemoveAreaModal('')
    }

    render() {
        const { currentArea }  = this.props.location.state.response
        return (
            <Modal isOpen={this.props.modal} toggle={this.props.toggleRemoveAreaModal.bind(this, '')}>
                <ModalHeader toggle={this.props.toggleRemoveAreaModal.bind(this, '')}>
                        Are you sure?
                </ModalHeader>
                    <ModalBody>
                                <Form>
                                    <Button 
                                        onClick={this.removeAndExit.bind(this, currentArea)}
                                        color="primary">
                                        Confirm
                                    </Button>
                                    <Button 
                                        color="secondary" 
                                        onClick={this.props.toggleRemoveAreaModal.bind(this, '')}>
                                        Cancel
                                    </Button>
                                </Form>
                    </ModalBody>
                </Modal>
        )
    }
}

export default withRouter(RemoveAreaModal)