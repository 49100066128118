import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    Input,
    Form
} from "reactstrap";
import { withFirebase } from "../Firebase";
//import { extend } from "jquery";

class DeleteProposalModal extends Component {

    constructor(props){
        super(props)
        this.state = {
            isSubmitting: false,
            proposalName: '',
            error: ''
        }
    }

    handleChange = ({ target: { value }}) =>{
        this.setState({ proposalName: value })
    }

    handleSubmit = (e) =>{
        e.preventDefault()
        const { proposalName } = this.state
        const { proposalId, proposalSnippetId } = this.props

        if(proposalName === this.props.proposalName){

            this.setState({ error: "", isSubmitting: true })
            this.props.firebase.userDRef(this.props.firebase.auth.currentUser.uid).collection('proposals')
            .doc(proposalId).delete()
            .then(()=>{
                this.props.firebase.userDRef(this.props.firebase.auth.currentUser.uid).collection('proposal-snippets')
                .doc(proposalSnippetId).delete()
                .then(()=>{
                    const fieldValue = this.props.firebase.auth.app.firebase_.firestore.FieldValue
                    this.props.firebase.userDRef(this.props.firebase.auth.currentUser.uid)
                        .update({ proposalsCount: fieldValue.increment(-1)})
                        .then(()=>{
                            this.setState({ isSubmitting: false })
                            this.props.toggleDeleteProposalModal(false)
                            this.props.removeProposalFromList(proposalSnippetId)
                        })
                })
            })
            .catch((error) =>{
                this.setState({ error: "Error adding proposal name", isSubmitting: false })
                console.error("Error adding document: ", error);
            }) 
        } else if(!proposalName){ this.setState({ error: `Proposal name required`})  } 
        else{ this.setState({ error: `Incorrect proposal name`}) }
        
    }

    render(){

        return (
            <>
            <Modal isOpen={this.props.modal} toggle={() => this.props.toggleDeleteProposalModal(false)}>
                <ModalHeader toggle={() => this.props.toggleDeleteProposalModal(false)}>
                    Are you sure you want to delete {this.props.proposalName}?
                </ModalHeader>
                    <ModalBody>
                                <Form
                                    onSubmit={(e) => this.handleSubmit(e)}>
                                    <div className="form-row">
                                        <div className="form-group col-md-6">
                                            <label htmlFor="areaName">Type in the proposal name to confirm</label>
                                            <Input
                                                type="text"
                                                htmlFor="proposalName"
                                                onChange={(e) => this.handleChange(e)}
                                                name="proposalName"
                                                //className={`form-control ${touched.areaName && errors.areaName ? "is-invalid" : ""}`}
                                            />
                                            <p style={{'color':'red'}}>{
                                            this.state.error ? this.state.error : this.state.isSubmitting ? 'Deleting...' : ''}</p>
                                            {/*<Error name="areaName" component="div" className="invalid-feedback" />*/}
                                        </div>
                                    </div>
                                    <Button color="primary"
                                        type="submit"
                                        disabled={this.state.isSubmitting}>
                                        Delete
                                    </Button>
                                    <Button color="secondary" onClick={() => this.props.toggleDeleteProposalModal(false)}>
                                        Cancel
                                    </Button>
                                </Form>
                </ModalBody>
            </Modal>
            </>
        );
    }
}
export default withFirebase(withRouter(DeleteProposalModal));
