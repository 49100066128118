/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { Component, useState } from 'react';
import { withFirebase } from "../Firebase";
import { 
    Button, 
    Modal, 
    ModalHeader, 
    ModalBody, 
    ModalFooter,
    Row,
    Col } from 'reactstrap';


const ForgotPass = (props) => {
  const {
    buttonLabel,
    className
  } = props;

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  return (
    <div>
      <Button color="link" onClick={toggle}>Forgot Password?</Button>
      <Modal isOpen={modal} toggle={toggle} className={className}>
        <ModalHeader toggle={toggle}>Recover Your Password</ModalHeader>
        <ModalBody>
            <PasswordForgetPage/>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default ForgotPass;

const PasswordForgetPage = () => (
    <div>
      <PasswordForgetForm />
    </div>
  );
  
  const INITIAL_STATE = {
    email: "",
    error: null
  };
  
  class PasswordForgetFormBase extends Component {
    constructor(props) {
      super(props);
      this.state = { ...INITIAL_STATE };
    }
    onSubmit = event => {
      const { email } = this.state;
      this.props.firebase
        .doPasswordReset(email)
        .then(() => {
          this.setState({ INITIAL_STATE });
        })
        .catch(error => {
          this.setState({ error });
        });
      event.preventDefault();
    };
  
    onChange = event => {
      this.setState({ [event.target.name]: event.target.value });
    };
    render() {
      const { email, error } = this.state;
      const isInvalid = email === "";
      return (
        <form onSubmit={this.onSubmit}>

          <Row className="mx-5">
          <input
            name="email"
            value={this.state.email}
            onChange={this.onChange}
            type="text"
            placeholder="Email Address"
          />
            </Row>

            <Row className="mx-5 pt-3">
          <Button outline color="Link" disabled={isInvalid} type="submit">
            Reset My Password
          </Button>
          {error && <p>error.message</p>}

        </Row>
        </form>

      );
    }
  }

  
  const PasswordForgetForm = withFirebase(PasswordForgetFormBase);
  export { PasswordForgetForm };
  