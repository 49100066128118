import React, { Component } from 'react'
import {
    Col,
    Row,
    Card,
    CardBody,
    CardHeader,
    Table
} from "reactstrap";
import { isFunctionOrConstructorTypeNode } from 'typescript';

export default class ProfitAndPrice extends Component {


    render() {
        const { tax, labor, discount, areas } = this.props
        const laborCost = labor ? parseFloat(labor) : 0
        let partsTotal = 0;
        Object.keys(areas).map(areaId =>{
            let areaSum = areas[areaId].items.length ?
                            (areas[areaId].items.length > 1) ? 
                                areas[areaId].items.reduce((item1, item2)=>{ 
                                
                                    const price1 = item1.price || 0
                                    const price2 = item2.price || 0

                                    const qty1 = item1.qty || 0
                                    const qty2 = item2.qty || 0

                                    return (price1*qty1) + (price2*qty2)
                                }) 
                                    : (areas[areaId].items[0].price*areas[areaId].items[0].qty) 
                                : 0
            partsTotal += areaSum 
        })
        const discountedTotal = ((partsTotal + laborCost)*(100-discount)/100)
        const total = discountedTotal + (discountedTotal*(tax/100))

        const rowStyles = {
            marginBottom: '20px'
        }

        const colStyles = (label) => ({
            fontSize: '1.2rem',
            textAlign: label ? 'right' : 'left',
        })

        return (
    
            <Row>
                <Col md="12">
                    <Card>
                        <CardHeader>
                        <h2 id="profit">Profit and Pricing Breakdown</h2>
                        </CardHeader>
                            <CardBody>
                            <Row style={rowStyles}>
                                    <Col style={colStyles(true)} sm={{ size: 2, offset: 1}}>
                                        Parts Total:
                                    </Col>
                                    <Col style={colStyles(false)} sm={2}>
                                       ${partsTotal.toFixed(2)}
                                    </Col>
                                    <Col style={colStyles(true)} sm={2}>
                                       Labor Total:
                                    </Col>
                                    <Col style={colStyles(false)} sm={2}>
                                        <input 
                                            name="labor" 
                                            align="left" 
                                            defaultValue={labor}
                                            step="1"
                                            min="0"
                                            onChange={this.props.handleChange}
                                            type="number"/>
                                    </Col>
                            </Row>
                            <Row  style={rowStyles}>
                                <Col style={colStyles(true)} sm={{ size: 2, offset: 1}}>
                                  Discount(%):
                                </Col>
                                <Col style={colStyles(false)} sm={2}>
                                   <input 
                                        name="discount" 
                                        type="number" 
                                        align="right"
                                        step="1"
                                        min="0"
                                        defaultValue={discount}
                                        onChange={this.props.handleChange}/>
                                </Col>
                                <Col style={colStyles(true)} sm={2}>
                                   Tax(%):
                                </Col>
                                <Col style={colStyles(false)} sm={2}>
                                    <input 
                                        defaultValue={tax}
                                        width="50px"
                                        onChange={this.props.handleChange}
                                        name="tax" 
                                        step="1"
                                        min="0"
                                        type="number"/>
                                </Col>
                            </Row>
                            <Row style={rowStyles}>
                                <Col style={colStyles(true)} sm={{ size: 2, offset: 5}}>
                                    <b>Total:</b>
                                </Col>
                                <Col style={colStyles(false)} sm={3}>
                                    <b>${total.toFixed(2)}</b>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        )
    }
}
