import React, { Component } from 'react'
import {
    Col,
    Row,
    Card,
    CardBody,
    CardHeader
} from "reactstrap";

export default class ProposalHistory extends Component {
    render() {
        return (
            <Row>
                <Col md="12">
                    <Card>
                    <CardHeader>
                        <h2 id="history">Proposal History</h2>
                    </CardHeader>
                    <CardBody>
                        <Row>
                        <Col sm={3}>
                        <p>User Made Changes</p>
                        </Col>
                        <Col sm={3}>
                        <p>10/05/2019 2:15</p>
                        </Col>
                        </Row>
                        <Row>
                        <Col sm={3}>
                        <p>Client Requested Changes</p>
                        </Col>
                        <Col sm={3}>
                        <p>10/14/2019 2:15</p>
                        </Col>
                        </Row>
                        <Row>
                        <Col sm={3}>
                        <p>User Made Changes</p>
                        </Col>
                        <Col sm={3}>
                        <p>10/19/2019 2:15</p>
                        </Col>
                        </Row>
                        <Row>
                        <Col sm={3}>
                        <p>Proposal Sent To Client</p>
                        </Col>
                        <Col sm={3}>
                        <p>10/22/2019 2:15</p>
                        </Col>
                        </Row>
                        <Row>
                        <Col sm={3}>
                        <p>Client Accepted</p>
                        </Col>
                        <Col sm={3}>
                        <p>10/31/2019 2:15</p>
                        </Col>
                        </Row>
                    </CardBody>
                    </Card>
                </Col>
             </Row>
        )
    }
}
