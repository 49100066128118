import React from "react";
import { withRouter } from 'react-router-dom';
import { withFirebase } from "../components/Firebase";
import './User.css'
// reactstrap components
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Card,
  CardHeader,
  CardBody,
  Col,
  Alert
} from "reactstrap";
import { withAuthorization } from "../components/Session";
import BusinessCardUpload from "../components/FileUpload/businessCardUpload";
import { number } from "yup";
import { isThisTypeNode } from "typescript";

class User extends React.Component {

  render() {
    return (
      <>
        <div className="content">
          <BusinessCard />
        </div>
      </>
    );
  }
}

const condition = authUser => !!authUser;
class BusinessCardBase extends React.Component{

  constructor(props){
    super(props)
    this.state = {
      businessCardUrl: "",
      email: "",
      organizationName: "",
      phoneNumber: null,
      showInProposals: true,
      showDeleteButton: false,
      visible: false,
      loading: false
    }
  }
  componentDidMount(){
    this.loadUserInfo()
  }

  loadUserInfo(){
    this.setState({ loading: true })
    this.props.firebase.userDRef(this.props.firebase.auth.currentUser.uid)
    .get().then(doc => {
      if (doc.exists) {
        //console.log("Document data:", doc.data());
        this.setState({ ...doc.data() }, ()=>{ this.setState({ loading: false })})
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
    })
    /*
    .then(() =>{
      this.state.areaRefIds.map((id, index) =>{
          this.props.firebase.userDRef(this.props.firebase.auth.currentUser.uid)
              .collection('proposals').doc(this.props.location.state.response.docRef)
              .collection('areas').doc(id).collection('areaItems')
              .get().then(items =>{
                  const { areas } = this.state 
                  
                  const areaName = this.state.areaNames[index]
                  areas[areaName] = []
                  items.forEach(item => {
                      let newItem = item.data()
                      newItem.deleted = false
                      areas[areaName].push(newItem)
                  });
                  this.setState({ areas })
              })
      })
    })
    */
    .catch(function (error) {
      console.log("Error getting document:", error);
    });
  }

  handleChange = ({ target }) =>{ 
    const { name, value } = target
    let updatedValue = value
    if(name === 'phoneNumber'){
      updatedValue = updatedValue.replace(/-/g,'').replace(')', '').replace('(', '')
      updatedValue = parseInt(updatedValue)
    }
    if (updatedValue === "true" || updatedValue == "false") {
      updatedValue = JSON.parse(updatedValue);
    }
    this.setState({ [name]: updatedValue }, () => { console.log(this.state[name])})
  }

  formatPhoneNumber(numbers){
    let phoneNumber = numbers ? numbers.toString().slice(0 ,10) : ''
    switch(true){
      case phoneNumber.length < 4:
        phoneNumber = phoneNumber.slice(0, 3)
        break;
      case phoneNumber.length >= 4 && phoneNumber.length < 7:
        phoneNumber = `(${phoneNumber.slice(0, 3)})-${phoneNumber.slice(3, 6)}`
        break;
      case phoneNumber.length >= 7:
          phoneNumber = `(${phoneNumber.slice(0, 3)})-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`
          break;
      default:
        break;
    }
    return phoneNumber
  }

  handleBatch = (e) =>{
    e.preventDefault()
    const { businessCardUrl, email,organizationName, phoneNumber, showInProposals } = this.state
     // create a batch write to update both proposals and snippets
    var batch = this.props.firebase.database.batch();

     //This deletes existing items on db that are unchecke
    const values = { businessCardUrl, email,organizationName, phoneNumber, showInProposals }
     
    var userRef = this.props.firebase.userDRef(this.props.firebase.auth.currentUser.uid)
    batch.update(userRef, values);
     // commit batch update
    batch.commit()
     .then(() => {
         this.setState({ visible: true }, () => {
           setTimeout(() => {
             this.setState({ visible: false })
           }, 2000)
           });
     })
     .catch(function (error) {
         console.error("Error adding document: ", error);
     });

  }

changeBusinessCardUrl = (url) =>{
  this.setState({ businessCardUrl: url })
}

 deleteBusinessCardURL = () =>{
  this.setState({ businessCardUrl: '', showDeleteButton: false })
 }

 toggleDeleteButton = () =>{
   const { showDeleteButton } = this.state
   const newState = !showDeleteButton
   this.setState({ showDeleteButton: newState })
 }

  render(){
    const { organizationName, phoneNumber, visible, loading,
            email, showInProposals, showDeleteButton, businessCardUrl} = this.state
    const lableStyle = {
       fontSize: '1rem'
    }
    
  return (
    <div>
      <Col sm="10" md={{ size: 9, offset: 1 }}>
      <Card>
        <CardHeader>
          <h3>Business Profile Info</h3>
          <p>These details are used in your proposals</p>
        </CardHeader>
        <CardBody >
          <Form>
          <FormGroup row>
              <Label style={lableStyle} for="business-name" sm={3}>Business or Organization</Label>
              <Col sm={5}>
                <Input 
                  onChange={this.handleChange}
                  defaultValue={organizationName}
                  id="organizationName" 
                  name="organizationName"
                  placeholder="Name"/>
              </Col>

            </FormGroup>
            <h3>Contact Information</h3>
            <FormGroup row>
              <Label style={lableStyle} for="phone" sm={3}>Phone Number</Label>
              <Col sm={5}>
                <Input 
                  id="phoneNumber" 
                  name="phoneNumber"
                  type="text"
                  onChange={this.handleChange}
                  value={this.formatPhoneNumber(phoneNumber)}
                  placeholder="(xxx)xxx-xxx"/>
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label style={lableStyle} for="Email" sm={3}>Email</Label>
              <Col sm={5}>
                <Input 
                  type="email" 
                  name="email" 
                  onChange={this.handleChange}
                  defaultValue={email}
                  id="email" 
                  placeholder="example@email.com"/>
              </Col>
            </FormGroup>
            
            <FormGroup row>
              <Label style={lableStyle} for="show-select" sm={3}>Show this contact info on your proposals</Label>
              <Col sm={5}>
                <Input 
                  onChange={this.handleChange}
                  defaultValue={showInProposals}
                  type="select" 
                  name="showInProposals" 
                  id="showInProposals">
                <option value={true}>yes</option>
                <option value={false}>no</option>
              </Input>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="card" style={lableStyle}  sm={3}> Business Card</Label>
              <Col sm={4}>
                {businessCardUrl ? 
                  <div 
                    onMouseEnter={this.toggleDeleteButton}
                    onMouseLeave={this.toggleDeleteButton}
                    className="business-card-box">
                    {showDeleteButton ? <button onClick={this.deleteBusinessCardURL} className="business-card-delete-button">X</button> : ''}
                    <img className="business-card" alt="business-card" src={this.state.businessCardUrl}/>
                  </div>
                  : <BusinessCardUpload
                      changeBusinessCardUrl={this.changeBusinessCardUrl}/>}
              </Col>
            </FormGroup>
            <FormGroup check row>
              <Col className="save-button-column">
                <Button
                  disabled={loading}
                  color="success"
                  onClick={this.handleBatch}>
                    Save
                </Button>
                <Alert className="business-profile-header-alert" isOpen={visible}>Business Profile Updated!</Alert>
              </Col>
            </FormGroup>
          </Form>
        </CardBody>
      </Card>
      </Col>
    </div>
  );
  }
}

const BusinessCard = withFirebase(BusinessCardBase)
export default withRouter(withAuthorization(condition)(User));