import app from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage"
const firebaseConfig = {
  apiKey: "AIzaSyBNWsrzDTwT1wWAsuJ2l5Ae3eI772PaQbc",
  authDomain: "react-test-c4db0.firebaseapp.com",
  databaseURL: "https://react-test-c4db0.firebaseio.com",
  projectId: "react-test-c4db0",
  storageBucket: "react-test-c4db0.appspot.com",
  messagingSenderId: "203955862724",
  appId: "1:203955862724:web:b2dd953b0ca46539"
};

class Firebase {
  constructor() {
    app.initializeApp(firebaseConfig);

    this.auth = app.auth();
    this.database = app.firestore();
    this.storage = app.storage();
    this.fv = app.firestore.FieldValue;
  }

  // *** AUTH API ***
  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);
  doSignOut = () => this.auth.signOut();
  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);
  doPasswordUpdate = password => this.auth.currentUser.updatePassword(password);

  // *** USER API ***
  userDRef = uid => this.database.doc(`users/${uid}`);
  usersCRef = () => this.database.collection("users");
  
  //** Storage API **
  doUploadFile = (file, fileName) => this.storage.ref(`items/${fileName}`).put(file)
  doGetHttpReference = (url) => this.storage.refFromURL(url)
  
}
export default Firebase;
