import React, { Component } from 'react'
import {
    Card,
    CardHeader,
    CardBody,
    Container, 
    Jumbotron,
    CardTitle,
    Row,
    Badge,
    ButtonToggle,
    Alert,
    Col
} from "reactstrap";
import { withRouter } from 'react-router'
import './LandingPage.css'
import ProposalScreenShotOne from '../assets/img/proposalScreenShot.png'
import ProposalScreenShotTwo from '../assets/img/proposalScreenShot2.png'
import BusinessProfileScreenShot from '../assets/img/businessProfileScreenShot.png'
import EmailScreenShot from '../assets/img/emailScreenShot.png'
import PDFPreviewPScreenShot from '../assets/img/pdfPreviewScreenShot.png'
import Pencil from '../assets/img/pencil.png'
import ClipBoard from '../assets/img/clipboard.png'
import Logo from "../assets/img/proposal.png";


class LandingPage extends Component {
    render() {
        return (
            <div className="content">
                <Row>
                    <Col lg={12}>
                        <Card>   
                            <Row>
                            <Col className="landing-page-top-column" md="3">  
                                <div className="landing-page-top-image-container">
                                    <img className="landing-page-top-image" src={ClipBoard}/>   
                                </div>             
                            </Col>
                            <Col md="6">
                                <Jumbotron className="landing-page-jumbo">  
                                        <Row>  
                                            <Container className="landing-page-title-container" fluid>
                                                <h1 className="landing-page-title">
                                                     <img width="120px" src={Logo} className="qouteix-logo" alt="qouteix logo"/>   
                                                    outeIX
                                                </h1>
                                            </Container>
                                        </Row>  
                                        <Row>   
                                            <Container className="landing-page-title-container" fluid>
                                                <p>Simple but effective proposal builder</p>
                                                <Col sm="12">
                                                    <Badge style={{fontSize: "1rem"}}>
                                                            Beta
                                                    </Badge>
                                                </Col>
                                                
                                                <Col sm="12">
                                                    <ButtonToggle 
                                                        onClick={() => this.props.history.push('/proposals')}
                                                        style={{marginTop: "30px", fontSize: "1.4vw"}} color="success"> 
                                                        Get Started
                                                    </ButtonToggle>
                                                </Col>
                                                <a 
                                                    style={{color: "red"}}
                                                    href="mailto: quoteix.contact@gmail.com?subject=Request to access Qoueix">
                                                    Request Access
                                                </a>
                                            </Container>        
                                        </Row>
                                </Jumbotron>
                            </Col>
                            <Col className="landing-page-top-column" md="3">
                                <div className="landing-page-top-image-container">
                                    <img className="landing-page-top-image" src={Pencil}/>
                                </div>
                            </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md="6">
                        <Card>
                            <CardHeader>
                                <h3 className="landing-page-desc-header">Personalized proposals for your projects</h3>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col>
                                        <img src={ProposalScreenShotTwo}/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="landing-page-desc">
                                        Create your own customized proposals
                                        with simple and easy steps for all your project needs. 
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md="6">
                        <Card>
                            <CardHeader>
                                <h3 className="landing-page-desc-header">Integrate your business profile</h3>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col>
                                        <img src={BusinessProfileScreenShot}/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="landing-page-desc">
                                        Set up your business profile with QouteIX to use it 
                                        in all of your proposals.
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>    
                    <Col md="6">
                        <Card>
                            <CardHeader>
                                <h3 className="landing-page-desc-header">Preview your PDF and send it to client!</h3>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col>
                                        <Row>
                                            <Col>
                                            <img src={PDFPreviewPScreenShot}/>
                                            </Col>
                                            <Col>
                                            <img src={EmailScreenShot}/>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="landing-page-desc">
                                        QouteIX automaticly generates a PDF link to send to your clients, which 
                                        allows them to view your proposal and submit feedback. It also tracks the 
                                        approval status of all your proposals, so you don't go through 
                                        thousands of emails, and track it yourself!
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card> 
                        <Card>
                            <CardHeader>
                                <h3 className="landing-page-desc-header">Ready to make some awesome proposals?</h3>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col md="12">
                                        <Container className="landing-page-bottom-button-container" fluid>
                                        <ButtonToggle 
                                            onClick={() => this.props.history.push('/proposals')}
                                            className="landing-page-bottom-button"
                                            color="success">
                                            Get Started
                                        </ButtonToggle>
                                        </Container>
                                    </Col> 
                                </Row>
                            </CardBody>
                        </Card> 
                   </Col>
                    <Col md="6">
                         <Card>
                             <CardHeader>
                                 <h3 className="landing-page-desc-header">Private {"&"} Public Item Database</h3>
                             </CardHeader>
                             <CardBody>
                                 <Row>
                                     <Col>
                                         <img src={ProposalScreenShotOne}/>
                                     </Col>
                                 </Row>
                                 <Row>
                                     <Col className="landing-page-desc">
                                         Save your items in the private item database to use them 
                                         in your other proposals or simply use items from the public 
                                         database so you can avoid the clutter of inputting same information
                                         over and over.
                                     </Col>
                                 </Row>
                             </CardBody>
                         </Card>           
                    </Col>
                </Row>           
        </div>
        )
    }
}

export default withRouter(LandingPage)
