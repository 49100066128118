import React, { Component } from 'react'
import {
    Col,
    Row,
    Card,
    CardBody,
    CardHeader,
    Label,
    Input,
    FormGroup,
} from "reactstrap";

const CoverPageAndAboutUs = props => {

        const lableStyle = {
            fontSize: '1rem'
        }
        
        return (
            <Row>
                <Col md="12">
                    <Card>
                    <CardHeader>
                        <h2 id="cover">Cover Page & About Us</h2>
                    </CardHeader>
                    <CardBody>
                        <FormGroup row>
                        <Label style={lableStyle} for="show-select" md="auto">Use Business Profile Info?</Label>
                        <Col md="1">
                            <Input 
                                type="select" 
                                name="useProfile" 
                                id="useProfile" 
                                onChange={props.handleChange}
                                defaultValue={props.useProfile}>
                                <option value={true}>Yes</option>
                                <option value={false}>No</option>
                            </Input>
                        </Col>
                        </FormGroup>
                        <FormGroup>
                            <Label style={lableStyle} for="exampleText">Additional Message To Client</Label>
                            <Input 
                                type="textarea" 
                                name="messageToClient" 
                                id="messageToClient" 
                                onChange={props.handleChange}
                                defaultValue={props.messageToClient}/>
                        </FormGroup>
                    </CardBody>
                    </Card>
                </Col>
            </Row>
        )
}

export default CoverPageAndAboutUs