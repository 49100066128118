import React, { Component } from 'react'
import { Page, Text, View, Font, Image, Document, StyleSheet } from '@react-pdf/renderer';
import NoImage from '../../../assets/img/noimage.jpeg'

const DocumentTemplate = (props) => {

    const styles = StyleSheet.create({
      bodyFirstPage: {
        paddingTop: 60,
        paddingBottom: 65,
        paddingHorizontal: 35,
      },
      title: {
        fontSize: 50,
        textAlign: 'center',
        fontFamily: 'Oswald'
      },
      author: {
        fontSize: 20,
        textAlign: 'center',
      },
      bottom: {
        marginTop: 535,
        display: 'flex',
        flexDirection: 'row',
        padding: 0,
        justifyContent: 'space-between',
        width: 520,
        marginLeft: 'auto',
        marginRight: 'auto',
      
      },
      bottomLeft: {
        fontSize: 15,
        textAlign: 'left'
      },
      bottomRight: {
        fontSize: 15,
        paddingRight: 0,
        textAlign: 'right',
        width: 200
      },
  
      image: {
        marginVertical: 15,
        marginHorizontal: 100,
        width: '100%',
      },
      
      body: {
        paddingTop: 50,
        paddingBottom: 65,
        paddingHorizontal: 35,
      },
      heading: {
        fontSize: 25,
        marginBottom: 20,
        textAlign: 'left',
        color: 'black',
      },
      text: {
        fontSize: 18,
        textAlign: 'justify',
        fontFamily: 'Times-Roman'
      },
  
      area: {
        marginBottom: 40
      },
  
      areaName: {
        marginBottom: 5,
        fontSize: 20,
        paddingLeft: 15
      },
  
      tableContainer : {
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'flex',
        flexDirection: 'column',
        border: 1,
      },
  
      tableRow : {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'start',
        alignContent: 'stretch',
        alignItems: 'stretch',
      },
  
      tableValue : {
        border: 1,
        width: 100,
        textAlign: 'center',
        paddingTop: 15,
      },
  
      total: {
        marginBottom: 15,
        paddingLeft: 15
      }
  
    });

    const { firstName, lastName, address, proposalName,
      email, city, zipCode, usState, areaOrder=[], imagesChanged, tax, 
      labor, discount, projectTerms, projectDescription, messageToClient, useProfile,
      areas={}} = props.clientInfo
  
    const { businessCardUr, userEmail, userOrganizationName, 
      showInProposals, userPhoneNumber } = props.userInfo
  
    const userPhoneNumberString = userPhoneNumber ? `(${userPhoneNumber.toString().slice(0, 3)})-${userPhoneNumber.toString().slice(3, 6)}-${userPhoneNumber.toString().slice(6, 10)}` : ''
   
    Font.register({
      family: 'Oswald',
      src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
    });

    const table = areaOrder.map(areaId =>{
         let total = 0
  
         return (
         <View key={areaId} style={styles.area}>
          <Text style={styles.areaName}>{
            Object.keys(areas).length ? areas[areaId].areaName : ''}
          </Text>
            <View style={styles.tableContainer}>
                <View style={styles.tableRow}>
                  <Text style={styles.tableValue}>Image</Text>
                  <Text style={styles.tableValue}>Name</Text>
                  <Text style={styles.tableValue}>Desc</Text>
                  <Text style={styles.tableValue}>Qty</Text>
                  <Text style={styles.tableValue}>Price</Text>
                </View>
                {
                  Object.keys(areas).length ? areas[areaId].items.map((item, index)=>{
                    total = total + parseFloat(item.price || 0)
                    return (
                      <View key={index} style={styles.tableRow}>
                        <Image style={styles.tableValue} 
                          src={props.imgURLs[areaId] ? (props.imgURLs[areaId][index] || NoImage ): NoImage}/>
                        <Text style={styles.tableValue}>{item.name}</Text>
                        <Text style={styles.tableValue}>{item.desc}</Text>
                        <Text style={styles.tableValue}>{item.qty}</Text>
                        <Text style={styles.tableValue}>${item.price}</Text>
                      </View>
                    )
                  }) : ''
                }
            </View>
          <Text style={styles.total}>Total: {total.toFixed(2)}</Text>
          </View>)
    })
  
    const laborCost = labor ? parseFloat(labor) : 0
      let partsTotal = 0;
      Object.keys(areas).map(areaId =>{
          let areaSum = areas[areaId].items.length ?
                          (areas[areaId].items.length > 1) ? 
                              areas[areaId].items.reduce((item1, item2)=>{ 
                                const price1 = item1.price || 0
                                const price2 = item2.price || 0

                                const qty1 = item1.qty || 0
                                const qty2 = item2.qty || 0
                                return (price1*qty1) + (price2*qty2)
                            }) 
                                  : (areas[areaId].items[0].price*areas[areaId].items[0].qty) 
                              : 0
          partsTotal += areaSum
    })

    const discountedTotal = ((partsTotal + laborCost)*(100-discount)/100)
    const total = discountedTotal + (discountedTotal*(tax/100))
    
    const document = (
        <Document>
            <Page style={styles.bodyFirstPage}>
              <Text style={styles.title}>{proposalName}</Text>
             
              <Text style={styles.author}>
                  {'Prepared by '}
                  {userOrganizationName}
              </Text>
  
              <View style={styles.bottom}>
                <Text style={styles.bottomLeft}>
                  {userOrganizationName}{`\n`}
                  {userPhoneNumberString}{`\n`}
                  {userEmail}
                </Text>
                <Text style={styles.bottomRight}>
                  {firstName + ' ' + lastName}{`\n`}
                  {email}{`\n`}
                  {address} {zipCode} {city}, {usState}
                </Text>
              </View>
            </Page>
  
            <Page style={styles.body}>
              <Text style={styles.heading}>
                Cover Page
              </Text>
              <Text style={styles.text}>
                {messageToClient}
              </Text>
            </Page>
  
            <Page style={styles.body}>
              <Text style={styles.heading}>
                Project Description
              </Text>
              <Text style={styles.text}>
                {projectDescription}
              </Text>
            </Page>
  
            <Page style={styles.body}>
              <Text style={styles.heading}>
                Areas and Items
              </Text>
              {table}
            </Page>
  
            <Page style={styles.body}>
              <Text style={styles.heading}>
                Profit and Pricing Breakdown
              </Text>
              <View>
                <Text style={{marginBottom: 7}}>Parts Total: ${partsTotal.toFixed(2)}</Text>
                <Text style={{marginBottom: 7}}>Labor Total: ${labor}</Text>
                <Text style={{marginBottom: 7}}>Discount: {discount}%</Text>
                <Text style={{marginBottom: 10}}>Tax: {tax}%</Text>
                <Text style={{ fontSize: 20 }}>Total : ${total.toFixed(2)}</Text>
              </View>
            </Page>
  
            <Page style={styles.body}>
              <Text style={styles.heading}>
                Project Terms
              </Text>
              <Text style={styles.text}>
                {projectTerms}
              </Text>
            </Page>
        </Document>)
    
    return document
}

export default DocumentTemplate