import React from "react";
import { withFirebase } from "../Firebase";
import ReactDOM from 'react-dom'
// form validation imports
import './proposalForm.css'
import StickyNav from './StickyNav/StickyNav'
import ClientInfo from './ClientInfo/ClientInfo'
import AreasAndItems from './AreasAndItems/AreasAndItems'
import ProfitAndPrice from './ProfitAndPrice/ProfitAndPrice'
import ProjectTerm from './ProjectTerm/ProjectTerm'
import ProposalHistory from './ProposalHistory/ProposalHistory'
import CoverPageAndAboutUs from "./CoverPageAndAboutUs/CoverPageAndAboutUs"
import ProjectDescription from './ProjectDescription/ProjectDescription'
import PdfPreviewModal from './PdfPreviewModal/PdfPreviewModal'
import EmailModal from './Email/EmailModal'
// reactstrap
import { couldStartTrivia, isThisTypeNode } from "typescript";
import { cssNumber, timers } from "jquery";
import { object, string } from "yup";
import PromptSaveModal from "./PromptSaveModal";

class ProposalForm extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      //Client values
      proposalName: '',
      firstName: '', lastName: '', address: '',
      address2: '', email: '', city: '', zipCode: '', usState: '',
      areaOrder: [], areas: {}, tax: null, 
      labor: null, discount: null, projectTerms: '', projectDescription: '',
      messageToClient: '', useProfile: false,
      //User values
      businessCardUrl : '', userEmail: '', userOrganizationName: '', 
      userPhoneNumber : null, showInProposals : false,
      //Non-default state values
      imagesChanged: [], visible: false, loading: false, 
      pdfPreviewModal: false, emailModal: false, formTouched: false,
    }
    
}

componentDidMount() { 
  // Update the document title using the browser API
  this.loadProposalInfo()
  this._mounted = true
}

componentWillUnmount(){
  this._mounted = false
}

setStateSafely(newState){
  if(this._mounted){
    this.setState(newState)
  }
}

loadProposalInfo(){

  this.setStateSafely({ loading: true })
  this.props.firebase.userDRef(this.props.firebase.auth.currentUser.uid)
  .collection('proposals').doc(this.props.location.state.response.docRef)
  .get().then(doc => {
    if (doc.exists) {
      //console.log("Document data:", doc.data());
      this.setStateSafely({ ...doc.data() }, () => { 
        const { areas } = this.state
        Object.keys(areas).map(key =>{
            areas[key].items.map(item =>{
              item.isAlreadyInDb = item.dbItem
            })
        })
        this.setStateSafely({ areas })
      })
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
  })
  .then(()=>{
    this.props.firebase.userDRef(this.props.firebase.auth.currentUser.uid)
      .get().then(userInfo =>{
          const { businessCardUrl, email, organizationName, phoneNumber, showInProposals }  = userInfo.data()
          const userFields = {
            businessCardUrl, userEmail : email, userOrganizationName: organizationName, 
            userPhoneNumber : phoneNumber, showInProposals
          }
          this.setStateSafely({ ...userFields })
     })
  })
  .then(() =>{
    setTimeout(() => {
      this.setStateSafely({ loading: false })
    }, 500);
  })
  /*
  .then(() =>{
    this.state.areaRefIds.map((id, index) =>{
        this.props.firebase.userDRef(this.props.firebase.auth.currentUser.uid)
            .collection('proposals').doc(this.props.location.state.response.docRef)
            .collection('areas').doc(id).collection('areaItems')
            .get().then(items =>{
                const { areas } = this.state 
                
                const areaName = this.state.areaNames[index]
                areas[areaName] = []
                items.forEach(item => {
                    let newItem = item.data()
                    newItem.deleted = false
                    areas[areaName].push(newItem)
                });
                this.setState({ areas })
            })
    })
  })
  */
  .catch(function (error) {
    console.log("Error getting document:", error);
  });

}

create_UUID(){
  var dt = new Date().getTime();
  var string = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxx'
  var uuid = string.replace(/[xy]/g, function(c) {
      var r = (dt + Math.random()*16)%16 | 0;
      dt = Math.floor(dt/16);
      return (c=='x' ? r :(r&0x3|0x8)).toString(16);
  });
  return uuid;
}

togglePdfPreviewModal = (newState) =>{
  return this.setState({ pdfPreviewModal: newState })
}

toggleEmailModal = () =>{
  const { emailModal } = this.state
  const newState = !emailModal
  this.setState({ emailModal: newState })
}

addArea = (areaName) => {
    const { areas, areaOrder } = this.state
    const uId = this.create_UUID()
    areas[uId] = {
      areaName,
      items: []
    }
    areaOrder.push(uId)
    this.setState({ areas, areaOrder, formTouched: true })
}
  
addItem = (areaId) =>{
    const emptyItem = {
        id: this.create_UUID(),
        imgURL: '',
        name: '',
        desc: '',
        qty: '',
        price: '',
        tags: [],
        dbItem: false,
        //temporary values
        deleted: false,
        isAlreadyInDb: false,
    }
    const { areas } = this.state
    const newItems = [...areas[areaId].items, emptyItem]
    areas[areaId].items = newItems
    this.setState({ areas, formTouched: true })
}

removeItem = (areaId, itemIndex) =>{
  const { areas } = this.state
  areas[areaId].items[itemIndex].deleted = true
  this.setState({ areas, formTouched: true })
}

checkItem = (areaId, itemIndex) =>{
  const { areas } = this.state
  const currentStatus = areas[areaId].items[itemIndex].dbItem
  areas[areaId].items[itemIndex].dbItem = !currentStatus
  this.setState({ areas, formTouched: true })
}

removeArea = (areaId) =>{
  const { areas } = this.state
  delete areas[areaId]
  const areaOrder = this.state.areaOrder.filter(id => id !== areaId)
  this.setState({ areas, areaOrder, formTouched: true })
}

handleItemChange = ({ target: { value, name } }, areaId, index) =>{
  const { areas } = this.state
  areas[areaId].items[index][name] = value
  this.setState({ areas, formTouched: true })
}

handleAreaChange = ({ target : { value }}, areaId)=>{
  const { areas } = this.state
  areas[areaId].areaName = value
  this.setState({ areas, formTouched: true })
}

handleClientInfoChange = ({ target }) =>{ 
  const { name, value } = target
  let updatedValue = value
  if (updatedValue === "true" || updatedValue == "false") {
    updatedValue = JSON.parse(updatedValue);
  }
  this.setState({ [name]: updatedValue, formTouched: true })
}

handleImageChange = (areaId, itemIndex, file, fileName) => {
  this.setState({ formTouched: true})
  const { imagesChanged } = this.state
  imagesChanged.push({ areaId, itemIndex, file, fileName : this.create_UUID() + fileName})
  this.setState({ imagesChanged, formTouched: true })
}

getImageReference = (url) => {
  const httpsReference = this.props.firebase.doGetHttpReference(url)
  return httpsReference
}
//* Batch functions
handleBatch = async (e) =>{
     e.preventDefault()

     this.setStateSafely({ loading: true })
     const { firstName, lastName, address, proposalName,
      address2, email, city, zipCode, areas, usState, areaOrder, imagesChanged,  
      tax, labor, discount, projectTerms, projectDescription, messageToClient, useProfile } = this.state

      // create a batch write to update both proposals and snippets
      var batch = this.props.firebase.database.batch();

      const allItems = {}
      //This deletes existing items on db that are unchecked
      const uncheckedDbItems = []
      //Keeps track of items are stored in user's private item database
      let userDbitemsCount = 0;

      for(const key of Object.keys(areas)){
        areas[key].items = areas[key].items.filter(item => { 
          if(!item.deleted){   
            delete item.deleted
            if(item.dbItem){
              //Save to user item database
              const { imgURL, name, desc, qty, price, tags } = item
              const itemInfo = { imgURL, name, desc, qty, price, tags }
              allItems[item.id] = itemInfo
              //Add the db item count if the item is not already in the database
              if(!item.isAlreadyInDb){ userDbitemsCount++ }
            }
            else if(item.isAlreadyInDb && !item.dbItem){
              uncheckedDbItems.push(item.id) 
              userDbitemsCount--
            }
            delete item.isAlreadyInDb
            return item
          } 
        }) 
      }

      const values = { firstName, lastName, address, proposalName,
        address2, email, city, zipCode, areas, areaOrder, usState,
        tax, labor, discount, projectTerms, projectDescription, messageToClient, useProfile }
      
      this.proposalBatchUpdate(values, batch)
      this.snippetsBatchUpdate(values, batch)
      this.itemsBatchUpdate(allItems, batch)
      this.itemsDeleteBatchUpdate(uncheckedDbItems, batch)
      this.numberOfDbitemsBatchUpdate(userDbitemsCount, batch)

      //Create async functions for saving uploading images and saving it to the database for each image
      const saveAllImages = imagesChanged.map(image => async () =>{
          const { areaId, itemIndex, file, fileName } = image
          const uploadTaskSnapShot = await this.props.firebase.doUploadFile(file, fileName)
          const url = await uploadTaskSnapShot.ref.getDownloadURL()
          areas[areaId].items[itemIndex].imgURL = url
          this.setStateSafely({ areas })
          await this.props.firebase.userDRef(this.props.firebase.auth.currentUser.uid).collection('proposals')
                                  .doc(this.props.location.state.response.docRef).update({ areas }) 
          return 
      })

      // commit batch update
      return await batch.commit()
      .then(() => {
        const { areas } = this.state
            Object.keys(areas).map(key =>{
            areas[key].items.map(item =>{
                item.isAlreadyInDb = item.dbItem
            })
            this.setStateSafely({ areas })
        });
      })
      .then(()=>{
        //Save all the images 
        Promise.all([...saveAllImages.map(func=>func())]).then(() =>{
            this.setStateSafely({ visible: true, formTouched: false })
            setTimeout(() => {
              this.setStateSafely({ loading: false  })
            }, 1000)
            setTimeout(() => {
              this.setStateSafely({ visible: false  })
            }, 2000)
        })
        .catch((error)=>{
          console.log("errror uploading images", error)
        })
      })
      .catch(function (error) {
          console.error("Error adding document: ", error);
      });

      /*
      if(imagesChanged.length){
          imagesChanged.map(image => {
            const { areaId, itemIndex, file, fileName } = image
            this.props.firebase.doUploadFile(file, fileName)
              .then(uploadTaskSnapShot =>{
                uploadTaskSnapShot.ref.getDownloadURL()
                    .then(url =>{        
                      areas[areaId].items[itemIndex].imgURL = url
                      this.setState({ areas })
                      this.props.firebase.userDRef(this.props.firebase.auth.currentUser.uid).collection('proposals')
                        .doc(this.props.location.state.response.docRef).update({ areas }) 
                    })  
              })
            
          })
          this.loadProposalInfo()
          this.setState({ imagesChanged: [] })
      }*/

}

snippetsBatchUpdate = (values, batch) =>{
  // reference to snippets
  var snippetsRef = this.props.firebase.userDRef(this.props.firebase.auth.currentUser.uid).collection('proposal-snippets').doc(this.props.location.state.response.proposalSnippetId);
  batch.update(snippetsRef, 
    {clientName: values.firstName + " " + values.lastName,
     proposalName: values.proposalName,
     lastModified: this.props.firebase.auth.app.firebase_.firestore.FieldValue.serverTimestamp()});
}

proposalBatchUpdate = (values, batch) =>{
  // reference to proposals
  var proposalsRef = this.props.firebase.userDRef(this.props.firebase.auth.currentUser.uid).collection('proposals').
  doc(this.props.location.state.response.docRef);
  batch.update(proposalsRef, {...values});

}

itemsBatchUpdate = (allItems, batch) =>{
  // reference to items
  var itemsRef = this.props.firebase.userDRef(this.props.firebase.auth.currentUser.uid).collection('items').doc('item1');
  batch.update(itemsRef, { ...allItems})
}

itemsDeleteBatchUpdate = (uncheckedDbItems, batch) =>{
  var itemsRef = this.props.firebase.userDRef(this.props.firebase.auth.currentUser.uid).collection('items').doc('item1');
  let updates = {};
  uncheckedDbItems.map(itemId =>{
    updates[itemId] = this.props.firebase.fv.delete()
  })
  batch.update(itemsRef, updates);
}

numberOfDbitemsBatchUpdate = (countToAdjust, batch) =>{  
  var userRef = this.props.firebase.userDRef(this.props.firebase.auth.currentUser.uid)
  const fieldValue = this.props.firebase.auth.app.firebase_.firestore.FieldValue
  batch.update(userRef, { dbItemsCount: fieldValue.increment(countToAdjust)});
}

render() {
    const { firstName, lastName, address, proposalName,
      address2, email, city, zipCode, areas, usState, areaOrder, imagesChanged, tax, 
      labor, discount, projectTerms, projectDescription, messageToClient, useProfile,
      loading, visible,  businessCardUrl, userEmail, userOrganizationName, 
      userPhoneNumber, showInProposals } = this.state

    const clientfields = { firstName, lastName, address, proposalName,
      address2, email, city, zipCode, areas, usState, areaOrder, imagesChanged,  
      tax, labor, discount, projectTerms, projectDescription, messageToClient, useProfile }
    
    const userfields = { businessCardUrl, userEmail, userOrganizationName, 
        userPhoneNumber, showInProposals }
    
    /*
    const { businessCardUrl, email, organizationName, 
      phoneNumber, proposalsCount, showInProposals } = this.state
      
    const userfields = { businessCardUrl, email, organizationName, 
      phoneNumber, proposalsCount, showInProposals }*/

    return (
      <div>
      <StickyNav 
        togglePdfPreviewModal={this.togglePdfPreviewModal}
        toggleEmailModal={this.toggleEmailModal}
        loading={loading}
        handleBatch={this.handleBatch}
        handleChange={this.handleClientInfoChange}
        visible={visible}
        formTouched={this.state.formTouched}
        proposalName={proposalName}/>
      <ClientInfo
          handleClientInfoChange={this.handleClientInfoChange}
          proposalName={proposalName}
          clientInfo={clientfields}/>
      <ProposalHistory/>
      <CoverPageAndAboutUs
        handleChange={this.handleClientInfoChange}
        useProfile={useProfile}
        messageToClient={messageToClient}/>
      <ProjectDescription
        handleChange={this.handleClientInfoChange}
        projectDescription={projectDescription}/>
      <AreasAndItems 
        areas={areas}
        areaOrder={areaOrder}
        addItem={this.addItem}
        addArea={this.addArea}
        removeItem={this.removeItem}
        checkItem={this.checkItem}
        removeArea={this.removeArea}
        imagesChanged={imagesChanged}
        handleAreaChange={this.handleAreaChange}
        handleItemChange={this.handleItemChange}
        handleImageChange={this.handleImageChange}
        proposalRef={this.props.location.state.response.docRef}/>
      <ProfitAndPrice
        areas={areas}
        tax={tax}
        labor={labor}
        discount={discount}
        handleChange={this.handleClientInfoChange}/>
      <ProjectTerm
       projectTerms={this.state.projectTerms}
       handleChange={this.handleClientInfoChange}/>
      <PdfPreviewModal
       formTouched={this.state.formTouched}
       handleBatch={this.handleBatch}
       setFormTouchedToFalse={() => this.setState({ formTouched: false })}
       clientInfo={clientfields}
       userInfo={userfields}
       getImageReference={this.getImageReference}
       togglePdfPreviewModal={this.togglePdfPreviewModal}
       toggleEmailModal={this.toggleEmailModal}
       modal={this.state.pdfPreviewModal}/>
      <EmailModal
        formTouched={this.state.formTouched}
        setFormTouchedToFalse={() => this.setState({ formTouched: false })}
        clientInfo={clientfields}
        handleBatch={this.handleBatch}
        toggleEmailModal={this.toggleEmailModal}
        modal={this.state.emailModal}/>
      {/*<Form>
      <Form.Row>
        <Form.Group as={Col} controlId="formGridEmail">
          <Form.Label>Client Name</Form.Label>
          <Form.Control type="name" placeholder="Enter Client Name" />
        </Form.Group>

        <Form.Group as={Col} controlId="formGridPassword">
          <Form.Label>Client Email</Form.Label>
          <Form.Control type="email" placeholder="Client@email.com" />
        </Form.Group>
      </Form.Row>

      <Form.Group controlId="formGridAddress1">
        <Form.Label>Address</Form.Label>
        <Form.Control placeholder="1234 Main St" />
      </Form.Group>

      <Form.Group controlId="formGridAddress2">
        <Form.Label>Address 2</Form.Label>
        <Form.Control placeholder="Apartment, studio, or floor" />
      </Form.Group>

      <Form.Row>
        <Form.Group as={Col} controlId="formGridCity">
          <Form.Label>City</Form.Label>
          <Form.Control />
        </Form.Group>

        <Form.Group as={Col} controlId="formGridState">
          <Form.Label>State</Form.Label>
          <Form.Control as="select">
            <option>Choose...</option>
            <option>...</option>
          </Form.Control>
        </Form.Group>

        <Form.Group as={Col} controlId="formGridZip">
          <Form.Label>Zip</Form.Label>
          <Form.Control />
        </Form.Group>
      </Form.Row>

      <Form.Group id="formGridCheckbox">
        <Form.Check type="checkbox" label="Check me out" />
      </Form.Group> 

      <Button variant="primary" type="submit">
        Save
  </Button>
      <h1 id="history">Proposal History</h1>
      <Card style={{ width: '18rem' }}>
        <Card.Header>Featured</Card.Header>
        <ListGroup variant="flush">
          <ListGroup.Item>Cras justo odio</ListGroup.Item>
          <ListGroup.Item>Dapibus ac facilisis in</ListGroup.Item>
          <ListGroup.Item>Vestibulum at eros</ListGroup.Item>
        </ListGroup>
      </Card>
      <h1 id="cover">Cover Page & About Us</h1>
      <Container>
        <Row>
          <Col xs={6} md={4}>
            <Image src="holder.js/171x180" rounded />
          </Col>
          <Col xs={6} md={4}>
            <Image src="holder.js/171x180" roundedCircle />
          </Col>
          <Col xs={6} md={4}>
            <Image src="holder.js/171x180" thumbnail />
          </Col>
        </Row>
      </Container>
      <h1 id="project">Project Description</h1>
      <InputGroup className="mb-3">
        <InputGroup.Prepend>
          <InputGroup.Text id="inputGroup-sizing-default">Default</InputGroup.Text>
        </InputGroup.Prepend>
        <FormControl
          aria-label="Default"
          aria-describedby="inputGroup-sizing-default"
        />
      </InputGroup>
      
      <h1 id="areas">Areas</h1>
      <Form.Group controlId="formBasicEmail">
        <Form.Label>Area Name</Form.Label>
        <Form.Control placeholder="Enter a name for the area" />
      </Form.Group>
      
      <h1 id="total">Profit & Pricing Breakdown</h1>

      <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
      <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>

   
    </Form>
    */}

      </div>
    );
}
}

export default withFirebase(ProposalForm);