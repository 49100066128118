import React, {useEffect, useState} from "react";
import { withRouter } from "react-router-dom";
import DeleteProposalModal from './DeleteProposalModal'
import * as ROUTES from "../../constants/routes";

// bootstrap
import {Button, Row, Col, Card} from 'reactstrap';

const ProposalButton = props => {

  useEffect(() => {
    // Update the document title using the browser API
  });
  // return (
  //   <div className="mt-3" style={{ backgroundColor: "#FFFFFF" }}>
  //     <Button className="text-left" variant="outline-dark" size="lg" href={ROUTES.PROPOSALVIEW} block >
  //       <Row><Col><h3>{props.proposalName}</h3></Col><Col><h3>{props.clientName}</h3></Col></Row>
  //       <Row><Col><p>last updated: {props.lastModified}</p></Col><Col><p>status: {props.status}</p></Col></Row>
  //     </Button>
  //   </div>
  // )
  const [visible, toggleDeleteButton] = useState(false);
  const [modal, toggleDeleteProposalModal] = useState(false);
  const [proposalButtonDisabled, toggleProposalButton] = useState(false)
  const onSubmit = event => {
    //props.history.push("/proposals/form", {response: {docRef: props.docRef, proposalName: props.proposalName}});
    //Use DocName instead to always ensure that data is being stored in the correct location
    event.preventDefault();
    if(!proposalButtonDisabled)
      props.history.push("/proposals/form", 
        {response: { docRef: props.docRef, proposalName: props.proposalName, proposalSnippetId : props.proposalSnippetId}});
  };
  
  const buttonStyle = {
    color: "red",
    fontWeight: "bold",
    fontSize: "0.8rem",
    background: "none",
    border: "none",
    position: "absolute",
    right: '3%',
    display: visible ? 'block' : 'none',
    zIndex: '1000'
  };

  return (
    <>
    <DeleteProposalModal
      toggleDeleteProposalModal={toggleDeleteProposalModal}
      removeProposalFromList={props.removeProposalFromList}
      modal={modal}
      proposalSnippetId={props.proposalSnippetId}
      proposalId={props.docRef}
      proposalName={props.proposalName}/>
    <div>
      <Button
        onMouseEnter={() => toggleDeleteButton(true)} 
        onMouseLeave={() => toggleDeleteButton(false)} 
        className="text-left" outline color="secondary" size="sm" 
        onClick={onSubmit} block >
        <Row>
          <Col xs="6"><h3>{props.proposalName}</h3></Col>
          <Col xs="4"><h3>{props.clientName}</h3></Col>
          <Col xs="2">
            <div
              onMouseEnter={() => toggleProposalButton(true)}
              onMouseLeave={() => toggleProposalButton(false)}
              onClick={() => toggleDeleteProposalModal(true)}
              style={buttonStyle} sm={{ size: '3', offset: 0 }}>
                Delete this proposal
            </div>
          </Col>
        </Row>
        <Row>
          <Col><p>last updated: {props.lastModified}</p></Col>
          <Col><p>status: {props.status}</p></Col>
        </Row>
      </Button>
    </div>
    </>
  );
};
export default withRouter(ProposalButton);
