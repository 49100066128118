import React, { Component } from "react";
// form validation imports
//import { Formik, Form, Field, ErrorMessage } from 'formik';
//import * as yup from 'yup';
import { withRouter } from "react-router-dom";
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    Input,
    Form
} from "reactstrap";
import { withFirebase } from "../Firebase";
//import { extend } from "jquery";

class CreateProposalModal extends Component {

    constructor(props){
        super(props)
        this.state = {
            isSubmitting: false,
            proposalName: '',
            error: ''
        }
    }

    handleChange = ({ target: { value }}) =>{
        
        this.setState({ proposalName: value })
    }

    handleSubmit = (e) =>{
        e.preventDefault()
        const { proposalName, firstName='', lastName='', address='', address2='', email='', 
        city='', zipCode='', usState='', areaOrder=[], areas={}, tax=null, labor=null, discount=null, 
        projectTerms='', projectDescription='', messageToClient='', useProfile=false} = this.state
       
        const newProposalValues = { firstName, lastName, address, proposalName,
            address2, email, city, zipCode, areas, usState, areaOrder,
            tax, labor, discount, projectTerms, projectDescription, messageToClient, useProfile }
        
        if(proposalName){
            
            this.setState({ error: "", isSubmitting: true })
            this.props.firebase.userDRef(this.props.firebase.auth.currentUser.uid).collection('proposals')
                .add(newProposalValues)
                .then(docRef => {
                    this.props.firebase.userDRef(this.props.firebase.auth.currentUser.uid).collection('proposal-snippets')
                        .add({  
                                proposalName: newProposalValues.proposalName,
                                createdOn: this.props.firebase.auth.app.firebase_.firestore.FieldValue.serverTimestamp(),
                                lastModified: this.props.firebase.auth.app.firebase_.firestore.FieldValue.serverTimestamp(),
                                proposalDocRef: docRef.id, 
                                status: "incomplete" 
                        })
                        .then((snippetRef) => {
                            const fieldValue = this.props.firebase.auth.app.firebase_.firestore.FieldValue
                            this.props.firebase.userDRef(this.props.firebase.auth.currentUser.uid)
                                .update({ proposalsCount: fieldValue.increment(1)})
                                .then(()=>{
                                    this.setState({ isSubmitting: false, error: ''})                        
                                    this.props.toggleCreateProposalModal();
                                    this.props.createNewProposal()
                                    this.props.history.push("/proposals/form", { response: { docRef: docRef.id, proposalName: newProposalValues.proposalName, proposalSnippetId: snippetRef.id } });
                                })
                        })
                })
                .catch((error) =>{
                    this.setState({ error: "Error adding proposal name", isSubmitting: false })
                    console.error("Error adding document: ", error);
                }) 
        } else{  this.setState({ error: `Proposal name required` })  }
    }

    render(){

        return (
            <>
            <Modal isOpen={this.props.modal} toggle={this.props.toggleCreateProposalModal}>
                <ModalHeader toggle={this.props.toggleCreateProposalModal}>
                        Create New Area
                </ModalHeader>
                    <ModalBody>
                                <Form
                                    onSubmit={(e) => this.handleSubmit(e)}>
                                    <div className="form-row">
                                        <div className="form-group col-md-6">
                                            <label htmlFor="areaName">Proposal Name</label>
                                            <Input
                                                type="text"
                                                htmlFor="proposalName"
                                                onChange={(e) => this.handleChange(e)}
                                                name="proposalName"
                                                //className={`form-control ${touched.areaName && errors.areaName ? "is-invalid" : ""}`}
                                            />
                                           <p style={{'color':'red'}}>{
                                            this.state.error ? this.state.error : this.state.isSubmitting ? 'Creating...' : ''}</p>
                                            {/*<Error name="areaName" component="div" className="invalid-feedback" />*/}
                                        </div>
                                    </div>
                                    <Button color="primary"
                                        type="submit"
                                        disabled={this.state.isSubmitting}>
                                        Create
                                    </Button>
                                    <Button color="secondary" onClick={this.props.toggleCreateProposalModal}>
                                        Cancel
                                    </Button>
                                </Form>
                </ModalBody>
            </Modal>
            </>
        );
    }
}
export default withFirebase(withRouter(CreateProposalModal));

//Formik based form fields
/*
    <>
            <Modal isOpen={this.props.modal} toggle={this.props.toggleCreateProposalModal}>
                <ModalHeader toggle={this.props.toggleCreateProposalModal}>
                    Create Proposal
            </ModalHeader>
                <ModalBody>
                    <Formik
                        initialValues={{ proposalName: '' }}
                        validationSchema={validationSchema}
                        onSubmit={(values, actions) => {
                            const { proposalName='', firstName='', lastName='', address='', address2='', email='', city='', zipCode='', usState='',
                            areaOrder=[], areas={}, tax=null, labor=null, discount=null, projectTerms='', projectDescription='',
                            messageToClient='', useProfile=false } = values

                            const newProposalValues = { firstName, lastName, address, proposalName,
                                address2, email, city, zipCode, areas, usState, areaOrder,
                                tax, labor, discount, projectTerms, projectDescription, messageToClient, useProfile }
                        
                            this.props.firebase.userDRef(this.props.firebase.auth.currentUser.uid).collection('proposals')
                                .add(newProposalValues)
                                .then(docRef => {
                                    this.props.firebase.userDRef(this.props.firebase.auth.currentUser.uid).collection('proposal-snippets').doc()
                                        .set({  
                                                proposalName: newProposalValues.proposalName,
                                                createdOn: this.props.firebase.auth.app.firebase_.firestore.FieldValue.serverTimestamp,
                                                proposalDocRef: docRef.id, 
                                                status: "incomplete" })
                                        .then(() => {
                                            actions.setSubmitting(false);
                                            this.props.toggleCreateProposalModal();
                                            this.props.history.push("/proposals/form", { response: { docRef: docRef.id, proposalName: values.proposalName } });
                                        })
                                })
                                // actions.setSubmitting(false);
                                .catch(function (error) {
                                    console.error("Error adding document: ", error);
                                    actions.setSubmitting(false);
                                    actions.setErrors();
                                });
                        }}
                    >
                        {({ isSubmitting, touched, errors }) => (
                            <Form>
                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label htmlFor="proposalName">Proposal Name</label>
                                        <Field
                                            type="text"
                                            name="proposalName"
                                            className={`form-control ${touched.proposalName && errors.proposalName ? "is-invalid" : ""}`}
                                        />
                                        <ErrorMessage name="proposalName" component="div" className="invalid-feedback" />
                                    </div>
                                </div>
                                <Button color="primary"
                                    type="submit"
                                    disabled={isSubmitting}>
                                    Create
                                </Button>
                                <Button color="secondary" onClick={this.props.toggleCreateProposalModal}>
                                    Cancel
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </ModalBody>
            </Modal>
            </>
        );*/